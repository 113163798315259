import React, { useEffect, useState } from 'react'
import StepReview from './StepReview'

const Sidebar = ({status1 , status2, status3}) => {
    
  return (
    <div className='sidebar d-lg-block d-none'>
        <div className='p-3 px-4 sidebar-header'>
            {process.env.REACT_APP_TITLE}
        </div>
        <div className='sidebar-content p-3'>
            <div className='siderbar-title'>Three simple steps to get compensation</div>
            <StepReview status={status1} title='Compensation' view={'sidebar'} description='Check your compensation amount'/>
            <StepReview status={status2} title='Details' view={'sidebar'} description='Add your personal details'/>
            <StepReview status={status3} title='Claim' view={'sidebar'} description='Submit your claim'/>
        </div>

    </div>
  )
}

export default Sidebar