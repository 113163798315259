
// import { Reviews } from '@mui/icons-material';
import Header from '../Header';
import About from '../PageComponents/About';
import Footer from '../PageComponents/Footer';
import Hero from '../PageComponents/Hero';
import HowItWorks from '../PageComponents/HowItWorks';
import InfoPart from '../PageComponents/InfoPart';
import Passengerrights from '../PageComponents/Passengerrights';
import Problem from '../PageComponents/Problem';
import ReadyClaim from '../PageComponents/ReadyClaim';
import Reviews from '../PageComponents/Reviews';

const Home = ({ compensationForm, setCompensationForm }) => {
  return (
    <div>
        <Header />
        <Hero compensationForm = {compensationForm} setCompensationForm={setCompensationForm}/>
        <InfoPart/>
        <HowItWorks/>
        <Problem/>
        <Passengerrights/>
        <About/>
        <ReadyClaim/>
        <Reviews/>
        <Footer/>
    </div>
  )
}

export default Home