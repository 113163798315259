import React from "react";

const Clients = () => {
  return (
    <div className="clients-wrapper">
      <div className="container">
        <div class="row">
          <div class="col-md-4 col-sm-4 text-center ">
            <div class="counter-entry">
              <div class="desc">
                <span
                  class="aa-counter js-counter"
                  data-from="0"
                  data-to="260000"
                  data-speed="5000"
                  data-refresh-interval="50"
                >
                  260,000+
                </span>
                <span class="aa-counter-label">Clients already trust us</span>
              </div>
            </div>
          </div>
          <div class="col-md-4 col-sm-4 text-center ">
            <div class="counter-entry">
              <div class="desc">
                <span
                  class="aa-counter js-counter"
                  data-from="0"
                  data-to="4.6"
                  data-speed="1"
                  data-refresh-interval="50"
                >
                  4.6
                </span>
                <span class="aa-counter-label">
                  Clients ranked us 4.6 out of 5 based on more than 16,147
                  reviews
                </span>
              </div>
            </div>
          </div>
          <div class="col-md-4 col-sm-4 text-center ">
            <div class="counter-entry">
              <div class="desc">
                <span
                  class="aa-counter js-counter"
                  data-from="0"
                  data-to="13"
                  data-speed="2"
                  data-refresh-interval="50"
                >
                  13
                </span>
                <span class="aa-counter-label">Languages</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Clients;
