import { faCaretDown, faQuestion } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Formbutton from "../Formbutton";
import FlightCompensationApi from "../../Api/FlightCompensationApi";
import ReloadReset from "../ReloadReset";

function Step14({ compensationForm, setCompensationForm }) {
  const flightCompensationApi = new FlightCompensationApi();
  const [activecontinue, setActiveContinue] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [form, setForm] = useState({
    details: null,
  });

  const navigate = useNavigate();

  const handleContinue = () => {
    setCompensationForm({
      ...compensationForm,
      step14: form,
    });

    let zendeskCase = {};

    zendeskCase.airline_company = compensationForm.step6.airline.name + "\n";
    zendeskCase.total_compensation = (compensationForm.step7.compensation.compensation - compensationForm.step7.compensation.refund_amount) + " " + compensationForm.step7.compensation.currency;
    zendeskCase.per_passenger = ((compensationForm.step7.compensation.compensation - compensationForm.step7.compensation.refund_amount) / compensationForm.step6.numberOfPerson) + " " + compensationForm.step7.compensation.currency;
    zendeskCase.additional = (parseInt(compensationForm.step7.compensation.refund_amount) + parseInt(compensationForm.step13.incur_amount ? compensationForm.step13.incur_amount : 0)) + " " + compensationForm.step7.compensation.currency;

    zendeskCase.disruption_details = 'heading'; // Heading
    zendeskCase.departure_airport = `${compensationForm.step1.departure.name} (${ compensationForm.step1.departure.code })`;
    zendeskCase.destination_airport = `${compensationForm.step1.destination.name} (${ compensationForm.step1.destination.code })`;
    zendeskCase.is_direct_flight = compensationForm.step2.isDirectFlight;

    let affectedFlight = compensationForm.step3.affectedFlight;
    zendeskCase.affected_flight = `${affectedFlight.from.name} (${affectedFlight.from.code})` + " > " + `${affectedFlight.to.name} (${affectedFlight.to.code})`;
    zendeskCase.affected_distance = compensationForm.step7.compensation.distance;
    zendeskCase.compensation_reason = compensationForm.step4.compensationReason;
    if (zendeskCase.compensation_reason === 'delay')
    {
      zendeskCase.total_delay = compensationForm.step5.delayTime.label;
      zendeskCase.delay_reason = compensationForm.step5.reason;
    } else if (zendeskCase.compensation_reason === 'cancelled')
    {
      zendeskCase.cancellation_notification = compensationForm.step5.cancelTime.label;
      zendeskCase.alternate_flight_offered = compensationForm.step5.alternate_flight_offered;

      if (zendeskCase.alternate_flight_offered === 'Yes')
      {
        zendeskCase.total_delay = compensationForm.step5.delayTime.label;
        zendeskCase.delay_reason = compensationForm.step5.reason;  
      } else {
        zendeskCase.ticket_refunded = compensationForm.step5.refunded;
        if (zendeskCase.ticket_refunded === 'No')
        {
          zendeskCase.ticket_price = compensationForm.step5.ticket_price;
        }
      }
    } else if (zendeskCase.compensation_reason === 'denied')
    {
      zendeskCase.give_up_seat = compensationForm.step5.giveUpSeat;
      if (zendeskCase.give_up_seat === 'No')
      {
        zendeskCase.alternate_flight_offered = compensationForm.step5.alternateFlightOffered;

        if (zendeskCase.alternate_flight_offered === 'Yes')
        {
          zendeskCase.total_delay = compensationForm.step5.delayTime.label;
        } else {
          zendeskCase.ticket_refunded = compensationForm.step5.refunded;
          if (zendeskCase.ticket_refunded === 'No')
          {
            zendeskCase.ticket_price = compensationForm.step5.ticket_price;
          }
        }
      }
    }



    if (compensationForm.step7.compensation.refund_amount || compensationForm.step13.incur_amount) 
    {
      zendeskCase.expense_details = 'heading' // Heading
      zendeskCase.ticket_to_be_refunded = (compensationForm.step7.compensation.refund_amount ? compensationForm.step7.compensation.refund_amount : 0 ) + " " + compensationForm.step7.compensation.currency;
      zendeskCase.extra_expenses = (compensationForm.step13.incur_amount ? compensationForm.step13.incur_amount : 0) + " " + compensationForm.step7.compensation.currency;
      zendeskCase.proof_provided = compensationForm.step13.receipt ? 'Yes' : 'No'
    }
    
    // Personal info
    zendeskCase.personal_details = 'heading' // Heading
    zendeskCase.number_of_passengers = compensationForm.step6.numberOfPerson; 
    zendeskCase.booking_reference = compensationForm.step7.booking_reference_number + "\n";

    zendeskCase.main_claimant = null;
    zendeskCase.first_name = compensationForm.step8.filerDetails.first_name; 
    zendeskCase.last_name = compensationForm.step8.filerDetails.last_name; 
    zendeskCase.email = compensationForm.step8.filerDetails.email; 
    zendeskCase.mobile_number = compensationForm.step10.mobile_number;


    // Address
    zendeskCase.address_details = null;
    zendeskCase.address = compensationForm.step10.address;
    zendeskCase.city = compensationForm.step10.city;
    zendeskCase.country = compensationForm.step10.country;
    zendeskCase.postal_code = compensationForm.step10.postal_code;

     
    
    if (compensationForm.step9.with_passengers == 'Yes')
    {

      for (let i = 0; i < compensationForm.step9.passengers.length; i++)
      {
        let passenger = compensationForm.step9.passengers[i];
        let number = i + 1;
        zendeskCase['fellow_passenger_#' + number ] = null;
        zendeskCase['passenger_' + number + '_first_name'] = passenger.first_name;
        zendeskCase['passenger_' + number + '_last_name'] = passenger.last_name;
        zendeskCase['passenger_' + number + '_under_18'] = passenger.under_18 ? 'Yes' : 'No';
        
        if (passenger.under_18 == false)
        {
          zendeskCase['passenger_' + number + '_email'] = passenger.email;
        }

        if (passenger.same_booking_reference == false)
        {
          zendeskCase['passenger_' + number + '_booking_reference'] = passenger.booking_reference;
        }
      }

      
    }


    if (form.details)
    {
      zendeskCase.additional_details =  null;
      zendeskCase.details = form.details;
    }
   
 

    // zendeskCase.agreed_to_privacy_policy = compensationForm.step8.privacy_policy_agreed ? 'Yes' : 'No';


    // if (compensationForm.step2.isDirectFlight == 'No')
    // {
    //   let connectedAirpots = Object.keys(compensationForm.step2.connectedAirport).map(key => compensationForm.step2.connectedAirport[key].name);
    //   zendeskCase.connected_airpots = connectedAirpots.join(' > ');

    // }

    // Step 3

    // Step 4

    // Step 5
    
    

   

    // // Flight Desruption consequences
    // zendeskCase.flight_desruption_consequences = 'heading';
    // zendeskCase.incur_extra_cost = compensationForm.step13.incur_extra_cost;

    // if (zendeskCase.incur_extra_cost === 'Yes')
    // {
    //   zendeskCase.incur_amount = 'EUR ' + compensationForm.step13.incur_amount;
    // }


    // // Flight compensation
    // zendeskCase.calculated_compensation = 'heading';
    


    // TODO: DOCUMENTS
    let documents = {
      expense_receipt: compensationForm.step13.receipt,
      evidences: compensationForm.step12.files,
      signature: compensationForm.step11.signatureImage,
    }

    // Create a FormData object and append the file
    const formData = new FormData();

    // Add each file to the FormData
    for (let i = 0; i < documents.evidences.length; i++) {
      formData.append('documents[]', documents.evidences[i]);
    }

    if (documents.expense_receipt)
    {
      for (let i = 0; i < documents.expense_receipt.length; i++) {
        formData.append('documents[]', documents.expense_receipt[i]);
      }
    }

    formData.append('documents[]', documents.signature);



    flightCompensationApi.uploadFilesToZendesk(formData).then(response => {
      
      flightCompensationApi.submitCaseToZendesk(zendeskCase, response.token, response.ticket.identifier).then(response => {
        console.log(response);
      })


    })

    

    navigate("/funnel/submit");
  };

  ReloadReset(compensationForm, "step12");
  return (
    <div className="main-container">
      <div
        className="d-flex mx-auto flex-column alight-items-center"
        style={{ width: "780px" }}
      >
        <h3>
          Please, <span className="fw-bold">add any other details</span> about
          your disrupted flight (optional)
        </h3>

        <div
          className="rounded p-4 mt-3 mb-4"
          style={{ backgroundColor: "#f3e3d6" }}
        >
          <Form.Group className="mb-3 col-12" controlId={`incur_amount`}>
            <Form.Control
              as="textarea" 
              rows={3}
              name={`details`}
              value={form.details}
              placeholder="Tell us what happend? (Optional)"
              onChange={(e) =>
                setForm({
                  ...form,
                  details: e.target.value,
                })
              }
            ></Form.Control>
          </Form.Group>

          <div
            className={`d-flex align-items-center p-3 rounded question mt-4 ${
              isOpen ? "open" : ""
            }`}
            style={{ backgroundColor: "white" }}
            onClick={() => (isOpen ? setIsOpen(false) : setIsOpen(true))}
          >
            <span
              className="me-3 d-flex justify-content-center align-items-center"
              style={{
                height: "1.5rem",
                width: "1.5rem",
                borderRadius: "50%",
                backgroundColor: "#ff6f00",
              }}
            >
              <FontAwesomeIcon icon={faQuestion} style={{ color: "#ffffff" }} />
            </span>
            <span>What should be written in detail?</span>
            <span className="ms-auto caret">
              <FontAwesomeIcon
                style={{
                  transform: `${isOpen ? "rotate(180deg)" : ""}`,
                }}
                icon={faCaretDown}
              />
            </span>
          </div>
          {isOpen && (
            <div
              className={`mt-2 rounded p-3 d-flex flex-column gap-3`}
              style={{
                backgroundColor: "white",
              }}
            >
              <ul className="p-0 ps-4 mb-0">
                <li>What time did you arrive at your final destination?</li>
                <li>What did the airline tell you, and when?</li>
                <li>What care did the airline provide, if any?</li>
                <li>How did the incident inconvenience you?</li>
                <li>Did you miss an event or lose money?</li>
              </ul>
              <p className="p-0 ps-2">
                The more specific details you can provide, the stronger your claim.
              </p>
            </div>
          )}
        </div>
    </div>

      <Formbutton
        handleContinue={handleContinue}
        text="Submit your claim"
        activecontinue={activecontinue}
      />
    </div>
  );
}

export default Step14;
