import React from 'react'
import uk from '../assets/uk.svg'
import usa from '../assets/usa.svg'
import eu from '../assets/eu.svg'
import canada from '../assets/canada.svg'
import international from '../assets/international.svg'

const Passengerrights = () => {
    let countries = [
        { img: uk , title:'UK'},
        { img: eu , title:'EU'},
        { img: usa , title:'USA'},
        { img: canada , title:'Canada'},
        { img: international , title:'International'},
    ]
  return (
    <div className='protect-passenger'>
        <div className='container'>
            <div className='outer__protect flex-column flex-xl-row gap-xl-0 gap-3 d-flex align-xl-items-center justify-content-between'>
                <p className="mt-0 mb-0 text-uppercase text-center text-lg-start">We protect air passenger rights</p>
                <ul className="d-flex flex-lg-row gap-3 gap-lg-0 flex-column align-items-center justify-content-between w-100">
                    {
                        countries.map((country,i)=>{
                            return(
                                <li className="d-flex align-items-center justify-content-start text-uppercase" key={i}>
                                    <span className="d-inline-flex align-items-center justify-content-start" style={{marginRight:'10px'}}>
                                        <img width="32" height="22" loading="lazy" decoding="async" src={country.img} alt="uk"/>
                                    </span> 
                                    IN THE {country.title}
                                </li>
                            )
                        })
                    }
                </ul>
            </div>
        </div>
    </div>
  )
}

export default Passengerrights