import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const ReloadReset = ({ compensationForm, step }) => {
    const navigate = useNavigate();

    const valuecheck = () => {
        if (step) {
            // Do something if step is valid
        } else {
            console.log('Noc form');
            navigate('/funnel');
        }
    };

    useEffect(() => {
        const handleLoad = () => {
            console.log('Checking load');
            valuecheck();
        };

        const handleBeforeUnload = (event) => {
            event.preventDefault();
            event.returnValue = '';
            console.log("Reloaded before");
        };

        window.addEventListener('load', handleLoad);
        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('load', handleLoad);
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [step]); // Adding step as a dependency to useEffect

    return null; // This component does not render anything
};

export default ReloadReset;