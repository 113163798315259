import React from "react";
import claimimg from '../assets/claimimage4.webp'
import { Link } from "react-router-dom";

const ReadyClaim = () => {
  return (
    <div className="claim_wrapper">
      <div className="container">
        <div className="position-relative">
          <div className="claim__desc col-lg-8">
            <h4 className="claim_desc_h4">Ready to claim your £520 (€600) now?</h4>
            <div className="claim_desc_button_w">
              <Link
                to="/funnel"
                className="regular-btn light default d-inline-flex align-items-center fw-bold justify-content-center"
              >
                Claim compensation
              </Link>
            </div>
            <p className="claim_desc_p fw-bold">It takes less than 5 minutes</p>
          </div>
          <div className="claim_w_media d-none d-lg-inline-flex align-items-center justify-content-center">
            <img
              width="500"
              loading="lazy"
              decoding="async"
              src={claimimg}
              alt="Claim compensation"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReadyClaim;
