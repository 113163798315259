import React, { useState } from 'react'
import styled from 'styled-components';

import Form from 'react-bootstrap/Form';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlane } from '@fortawesome/free-solid-svg-icons';
import flight1 from '../assets/flight1.svg'
import flight2 from '../assets/flight2.svg'
import flight3 from '../assets/flight3.svg'
import Checkbox from './Checkbox';
import Option from './Option';
import ReloadReset from '../ReloadReset';
import Formbutton from '../Formbutton';


const Step4 = ({compensationForm, setCompensationForm}) => {
    const [selectedOption, setSelectedOption] = useState(null);
    const [activecontinue , setActiveContinue] = useState(false);
    const navigate = useNavigate();
    const handleContinue = ()=>{
        setCompensationForm({...compensationForm, step4: {compensationReason: selectedOption}})
        navigate('/funnel/step5')
        // console.log(selectedOption);

    }
    ReloadReset(compensationForm, 'step3');
  return (
    <div className='main-container d-flex flex-column p-5 justify-content-between' style={{minHeight:'100vh'}}>
        
        <div className='mx-auto'>
            <h1 className='text-center'>What exactly happened to your flight?</h1>
            <div className='form2 mb-5'>
                {/* {compensationForm.step2.map((item)=>{
                    return( */}
            <Option className='formoption'
                selected={selectedOption === 'delay'}
                onClick={() =>{ setActiveContinue(true); setSelectedOption('delay')}}
            >

                <Checkbox selected={selectedOption === 'delay'} image={true}>
                    <div className='flight'>
                        <img src={flight1} className='flight-img'></img>
                    </div>
                    <p>Flight Delay</p>
                </Checkbox>
            </Option>
            <Option className='formoption'
                selected={selectedOption === 'cancelled'}
                onClick={() =>{ setActiveContinue(true); setSelectedOption('cancelled')}}
            >

                <Checkbox selected={selectedOption === 'cancelled'} image={true}>
                    <div className='flight'>
                        <img src={flight2} className='flight-img'></img>
                    </div>
                    <p>Flight Cancellation</p>
                </Checkbox>
            </Option>
            <Option className='formoption'
                selected={selectedOption === 'denied'}
                onClick={() =>{ setActiveContinue(true); setSelectedOption('denied')}}
            >

                <Checkbox selected={selectedOption === 'denied'} image={true}>
                    <div className='flight'>
                        <img src={flight3} className='flight-img'></img>
                    </div>
                    <p>Denied Boarding</p>
                </Checkbox>
            </Option>
                    
            
            </div>
        </div>
        <Formbutton handleContinue={handleContinue} activecontinue={activecontinue}/>
    </div>
  )
}

export default Step4