import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react'
import { Button } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'

const Formbutton = ({activecontinue, handleContinue, text, backDisable}) => {
    const navigate = useNavigate();
  return (
    <div className='position-fixed bottom-btn d-flex justify-content-between'  style={{backgroundColor:'#ffffff78' , bottom:'0' ,padding:'1.5rem', paddingRight:'9rem' , right : '0'}}>

            <Button variant=" col-6 mt-auto" disabled={backDisable? true : false} onClick={()=>navigate(-1)} style={{border:'none'}} >
            <FontAwesomeIcon icon={faChevronLeft} /> Step back
            </Button>
            <Button variant=" fw-bold col-3 mt-auto text-white" disabled={!activecontinue? true : false}  onClick={handleContinue} style={{backgroundColor:'#ff6f00'}} >
                {text? text :'Continue'}
            </Button>
        </div>
  )
}

export default Formbutton