import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ReloadReset from "../ReloadReset";
import Option from "./Option";
import Checkbox from "./Checkbox";
import { Form } from "react-bootstrap";
import DragNdrop from "../DragNDrop";
import Formbutton from "../Formbutton";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faQuestion } from "@fortawesome/free-solid-svg-icons";

function Step13({ compensationForm, setCompensationForm }) {
  const [activecontinue, setActiveContinue] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [form, setForm] = useState({
    incur_extra_cost: null, // Yes, No
    incur_amount: 0,
    has_expenses_receipts: null,
  });

  const navigate = useNavigate();

  const [receipt, setReceipt] = useState([]);

  useEffect(() => {
    if (
        (form.incur_extra_cost == 'Yes' && form.incur_amount !== null && ((form.has_expenses_receipts == 'Yes' && receipt.length) || form.has_expenses_receipts == 'No')) ||
        form.incur_extra_cost == 'No'
    ) {
      setActiveContinue(true);
    } else {
      setActiveContinue(false);
    }
  }, [receipt, form]);

  const handleContinue = () => {

    let data = {
        incur_extra_cost: form.incur_extra_cost
    };
    if (form.incur_extra_cost === 'Yes')
    {
        data = {
            ...data,
            incur_extra_cost: form.incur_extra_cost,
            incur_amount: form.incur_amount,
            has_expenses_receipts: form.has_expenses_receipts === "Yes",
            receipt: form.has_expenses_receipts === "Yes" ? receipt : null,
        }
    }

    setCompensationForm({
      ...compensationForm,
      step13: data,
    });

    navigate("/funnel/step14");
  };

  ReloadReset(compensationForm, "step12");
  return (
    <div className="main-container">
      <div
        className="d-flex mx-auto flex-column alight-items-center"
        style={{ width: "780px" }}
      >
        <h3>
          Did you incur <span className="fw-bold">extra costs</span> due to flight disruption?
        </h3>

        <div className="row my-4">
          <div className="col-lg-6">
            <Option
              className="formoption w-100 mx-0"
              selected={form.incur_extra_cost === "No"}
              onClick={() =>
                setForm({
                  ...form,
                  incur_extra_cost: "No",
                })
              }
            >
              <Checkbox selected={form.incur_extra_cost === "No"}>
                <span className="fw-bold fs-3">No</span>
              </Checkbox>
            </Option>
          </div>
          <div className="col-lg-6">
            <Option
              className="formoption w-100 mx-0"
              selected={form.incur_extra_cost === "Yes"}
              onClick={() =>
                setForm({
                  ...form,
                  incur_extra_cost: "Yes",
                })
              }
            >
              <Checkbox selected={form.incur_extra_cost === "Yes"}>
                <span className="fw-bold fs-3">Yes</span>
              </Checkbox>
            </Option>
          </div>
        </div>

        {form.incur_extra_cost === "Yes" && (
          <div>
            <div
              className="rounded p-4 mt-3 mb-4"
              style={{ backgroundColor: "#f3e3d6" }}
            >
              <Form.Group className="mb-3 col-12" controlId={`incur_amount`}>
                <Form.Label className="ms-2">
                  Specify Total Amount in EUR
                </Form.Label>

                <Form.Control
                  name={`incur_amount`}
                  value={form.incur_amount}
                  onChange={(e) =>
                    setForm({
                      ...form,
                      incur_amount: e.target.value,
                    })
                  }
                ></Form.Control>
              </Form.Group>
            </div>

            <h3>
              <span className="fw-bold">Do you have the receipts</span> to prove
              your expenses?
            </h3>

            <div className="row my-4">
              <div className="col-lg-6">
                <Option
                  className="formoption w-100 mx-0"
                  selected={form.has_expenses_receipts === "No"}
                  onClick={() =>
                    setForm({
                      ...form,
                      has_expenses_receipts: "No",
                    })
                  }
                >
                  <Checkbox selected={form.has_expenses_receipts === "No"}>
                    <span className="fw-bold fs-3">No</span>
                  </Checkbox>
                </Option>
              </div>
              <div className="col-lg-6">
                <Option
                  className="formoption w-100 mx-0"
                  selected={form.has_expenses_receipts === "Yes"}
                  onClick={() =>
                    setForm({
                      ...form,
                      has_expenses_receipts: "Yes",
                    })
                  }
                >
                  <Checkbox selected={form.has_expenses_receipts === "Yes"}>
                    <span className="fw-bold fs-3">Yes</span>
                  </Checkbox>
                </Option>
              </div>
            </div>

            {form.has_expenses_receipts === "Yes" && (
              <div className="d-flex flex-column gap-3">
                <div
                  className="rounded p-4 mt-3 mb-4"
                  style={{ backgroundColor: "#f3e3d6" }}
                >
                  <h3>
                    <span className="fw-bold">Upload</span> your receipts.
                  </h3>
                  <DragNdrop
                    onFilesSelected={setReceipt}
                    width="100%"
                    height="100%"
                  />

                  <div
                    className={`d-flex align-items-center p-3 rounded question mt-4 ${
                      isOpen ? "open" : ""
                    }`}
                    style={{ backgroundColor: "white" }}
                    onClick={() =>
                      isOpen ? setIsOpen(false) : setIsOpen(true)
                    }
                  >
                    <span
                      className="me-3 d-flex justify-content-center align-items-center"
                      style={{
                        height: "1.5rem",
                        width: "1.5rem",
                        borderRadius: "50%",
                        backgroundColor: "#ff6f00",
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faQuestion}
                        style={{ color: "#ffffff" }}
                      />
                    </span>
                    <span>What counts as an extra cost?</span>
                    <span className="ms-auto caret">
                      <FontAwesomeIcon
                        style={{
                          transform: `${isOpen ? "rotate(180deg)" : ""}`,
                        }}
                        icon={faCaretDown}
                      />
                    </span>
                  </div>
                  {isOpen && <div
                    className={`mt-2 rounded p-3 d-flex flex-column gap-3`}
                    style={{
                      backgroundColor: "white",
                    }}
                  >
                    <ul className="p-0 ps-4 mb-0">
                      <li>Alternative flight tickets</li>
                      <li>Train/Taxi costs</li>
                      <li>Hotel bookings</li>
                      <li>Food, drinks and other necessities</li>
                    </ul>
                    <p className="p-0 ps-2">Costs shall be proved with receipts or invoices.</p>
                  </div>}
                </div>
              </div>
            )}
          </div>
        )}
      </div>

      <Formbutton
        handleContinue={handleContinue}
        text="Continue"
        activecontinue={activecontinue}
      />
    </div>
  );
}

export default Step13;
