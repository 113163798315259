import React, { useState } from 'react'
import faq_bg from '../assets/faq_bg.jpg'
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Form from 'react-bootstrap/Form';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
import { MenuItem, Select, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import Header from '../Header';




const Faq = () => {
    const VisuallyHiddenInput = styled('input')({
        clip: 'rect(0 0 0 0)',
        clipPath: 'inset(50%)',
        height: 1,
        overflow: 'hidden',
        position: 'absolute',
        bottom: 0,
        left: 0,
        whiteSpace: 'nowrap',
        width: 1,
      });
    const [search , setSearch] = useState();
    const [askForm, setAskForm] = useState({
        email:'',
        name:'',
        phone:'',
        topic:'',
        message:''
    })
    let faqType = [ '', '']
    let faq = [
        {
            faqType: 'What does this mean',
            faq: [
                {
                question: 'Faq question 1',
                answer:'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.'
                },
                {
                    question: 'Faq question 2',
                    answer:'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.'
                },

                {
                    question: 'Faq question 3',
                    answer:'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.'
                },
                {
                    question: 'Faq question 4',
                    answer:'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.'
                },
                {
                    question: 'Faq question 5',
                    answer:'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.'
                }
            ]
        },
        {
            faqType: 'Exceptions',
            faq: [
                {
                question: 'Faq question 1',
                answer:'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.'
                },
                {
                    question: 'Faq question 2',
                    answer:'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.'
                },

                {
                    question: 'Faq question 3',
                    answer:'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.'
                },
                {
                    question: 'Faq question 4',
                    answer:'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.'
                },
                {
                    question: 'Faq question 5',
                    answer:'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.'
                }
            ]
        },
        {
            faqType: 'Compensation in special cases',
            faq: [
                {
                question: 'Faq question 1',
                answer:'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.'
                },
                {
                    question: 'Faq question 2',
                    answer:'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.'
                },

                {
                    question: 'Faq question 3',
                    answer:'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.'
                },
                {
                    question: 'Faq question 4',
                    answer:'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.'
                },
                {
                    question: 'Faq question 5',
                    answer:'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.'
                }
            ]
        },  
    ];
    const handleChange = (e) => {
        const { name , value } = e.target;
        setAskForm({...askForm, [name]: value});
    };
    const handleSend = () =>{
        console.log(askForm);
    };

  return (
    <div className='wrapper'>
        <Header />
        <div className='faq-top-section' style={{backgroundImage:`url('${faq_bg}')`}}>
            <div className='container'>
                <div className='row'>
                    <h1>{process.env.REACT_APP_TITLE} Faq</h1>
                    <Form.Group className="col-md-6 position-relative" controlId="formBasicEmail">
                        <Form.Control type="text" name='search' className='relative icon-input' value={search} onChange={(e)=>setSearch(e.target.value)} placeholder="Search" >
                        </Form.Control>
                        <FontAwesomeIcon className='position-absolute' style={{ right: '20px', top:'30%', width:'1.5rem', height:'1.5rem', color: 'grey' }} icon={faMagnifyingGlass} />
                    </Form.Group>
                </div>
            </div>
        </div>
        <div className='mt-5 container'>
            <div className='row gap-4'>
                <div className='col-lg-7'>
                    {
                        faq.map((accordion)=>{
                            return(
                                <>  
                                    <h1 className='mt-4'>{accordion.faqType}</h1>
                                    {
                                        accordion.faq.map((faq)=>{
                                            return(
                                                <>
                                                <Accordion className='mt-3 faq-accordian shadow-sm'>
                                                    <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel1-content"
                                                    id="panel1-header"
                                                    className='px-4 py-2 '
                                                    >
                                                    <h3 >{faq.question}</h3> 
                                                    </AccordionSummary>
                                                    <hr/>
                                                    <AccordionDetails>
                                                    {faq.answer}
                                                    </AccordionDetails>
                                                </Accordion>
                                                </>
                                            )
                                        })
                                    }

                                    
                                </>
                            )
                        })
                    }
                    
                </div>
                <div className='col-lg-3 p-4 bg-white' style={{height:'fit-content'}}>
                    <h3>Ask Question</h3>
                    <Form.Control type="email" name='email' className='mt-3 icon-input' value={askForm.email} onChange={handleChange} placeholder="Email" />
                    <Form.Control type="text" name='name' className='mt-3 icon-input' value={askForm.name} onChange={handleChange} placeholder="Name" />
                    <Form.Control type="text" name='phone' className='mt-3 icon-input' value={askForm.phone} onChange={handleChange} placeholder="Phone" />
                    <Select
                        // labelId="demo-simple-select-label"
                        // id="demo-simple-select"
                        value={askForm.topic}
                        label="Age"
                        name='Topic*'
                        onChange={handleChange}
                        className='col-12 mt-3'
                    >
                        <MenuItem value='What does this mean'>What does this mean</MenuItem>
                        <MenuItem value='Exceptions'>Exceptions</MenuItem>
                        <MenuItem value='Compensation in special cases'>Compensation in special cases</MenuItem>
                        <MenuItem value='About AirAdvisor'>About AirAdvisor</MenuItem>
                        <MenuItem value='Right to compensation'>Right to compensation</MenuItem>
                    </Select>
                    <TextField
                        id="outlined-multiline-static"
                        label="Message*"
                        multiline
                        rows={4}
                        className='mt-3 col-12'
                        defaultValue="Default Value"
                        placeholder='Message *'
                        onChange={handleChange}
                        value={askForm.message}
                        />
                    <div className='mt-3 row col-12' style={{margin: '0'}}>
                    {/* <Form.Control type="text" disabled className='icon-input col-5'  placeholder="Upload File" /> */}
                        <div className='col-9 p-3' style={{border: '1px solid #DEE2E6', borderRadius:'0'}}>Upload File</div>
                        <Button
                            component="label"
                            role={undefined}
                            variant="contained"
                            tabIndex={-1}
                            className='col-3 rounded-0'
                            // startIcon={<CloudUploadIcon />}
                            >
                            ...
                            <VisuallyHiddenInput type="file" />
                        </Button>
                    </div>
                    <Button className='mt-3' variant="contained" onClick={handleSend}>Send</Button>
                </div>
            </div>
            

        </div>
        <div className='compensation mt-5'>
                <div className='container d-flex justify-content-center p-3'>
                    <div className='compensation-content p-3'>
                        <h2 className='text-center'>We'll help you get compensation for last 3 years, if your flight was delayed, cancelled or if you were denied boarding.</h2>
                        <Button className='fw-bold px-4 py-3 rounded-0' variant="contained" href="/funnel">
                            Check Compensation
                        </Button>
                        <p style={{letterSpacing: '2.8px'}}>THIS TAKES FEW MINUTES</p>
                    </div>
                </div>
            </div>
    </div>
  )
}

export default Faq