import React, { useEffect, useState } from "react";
import bgMedia from "../assets/dotscompensation.svg";
import compensationplane from "../assets/compensationplane.svg";
import { faCaretDown, faQuestion } from "@fortawesome/free-solid-svg-icons";
import { Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FlightCompensationApi from "../../Api/FlightCompensationApi";
import ReloadReset from "../ReloadReset";
import { useNavigate } from "react-router-dom";
import Formbutton from "../Formbutton";

const Step7 = ({ compensationForm, setCompensationForm }) => {

  const flightCompensationApi = new FlightCompensationApi();
  const navigate = useNavigate();
  const [activecontinue, setActiveContinue] = useState(false);


  const [compensation, setCompensation] = useState({
    currency: "EUR",
    compensation: 0,
    refund_amount: 0,
    distance: 0,
  });

  const [referenceNumber, setReferenceNumber] = useState();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {

    // Format data
    let data = {
      compensation_reason: compensationForm?.step4?.compensationReason,
      number_of_person: compensationForm?.step6?.numberOfPerson,
      departure: compensationForm?.step3?.affectedFlight?.from.id,
      destination: compensationForm?.step3?.affectedFlight?.to.id,
    };

    switch (data.compensation_reason) {
      case 'delay':
        data = {
          ...data,
          delay: compensationForm?.step5?.delayTime?.value,
        }
        break;
      case 'cancelled':
        data = {
          ...data,
          cancellation_notification: compensationForm?.step5?.cancelTime?.value,
          alternate_flight_provided: compensationForm?.step5?.alternateFlightOffered,
          total_delay: compensationForm?.step5?.totalDelay?.value,
          refunded: compensationForm?.step5?.refunded,
          ticket_price: compensationForm?.step5?.ticketPrice,
        }
        break;
      case 'denied':
        data = {
          ...data,
          seat_giveup: compensationForm?.step5?.giveUpSeat,
          total_delay: compensationForm?.step5?.delayTime?.value,
          refunded: compensationForm?.step5?.refunded,
          ticket_price: compensationForm?.step5?.ticketPrice,
        }
        break;
      default:
        break;
    }

    flightCompensationApi.calculateCompesation(data).then((res) => {


      setCompensation({
        currency: res.currency,
        compensation: res.compensation_amount,
        refund_amount: res.refund_amount,
        distance: res.distance
      })



    }).catch((err) => {
      console.log(err);
    })

  }, []);

  const handleContinue = () => {
    setCompensationForm({...compensationForm, step7: {
      booking_reference_number: referenceNumber,
      compensation: compensation
    }})

    navigate('/funnel/step8')
  };

  useEffect(() => {

    if (referenceNumber) {
      setActiveContinue(true);
    } else {
      setActiveContinue(false);
    }

  }, [referenceNumber])

  ReloadReset(compensationForm, 'step6');
  return (
    <div className="main-container d-flex justify-content-center mt-5">
      <div style={{ display: "inline-block", width: "100%" }}>
        <div className="compensation__confirmed">
          <div className="media position-relative">
            <div className="bg-media position-absolute">
              <img src={bgMedia} height="116" width="86" />
            </div>
            <img src={compensationplane} height="80" width="80" />
          </div>
          <div className="d-flex flex-column align-items-start">
            <h3 className="text-primary">{compensation?.currency} {compensation?.compensation}</h3>
            <p className="text-seconday">
              After analysing your application, we are pleased to inform you
              that you can be compensated with {compensation.currency} {compensation.compensation}.
            </p>
          </div>
        </div>
        <div className="rounded p-4 mt-3" style={{ backgroundColor: "#f3e3d6" }}>
          <Form.Group
            className="mb-3 col-12 position-relative"
            controlId="formBasicEmail"
          >
            <Form.Label>What is your booking reference number?</Form.Label>
            <Form.Control
              type="text"
              name="referenceNumber"
              value={referenceNumber}
              onChange={(e) => setReferenceNumber(e.target.value)}
              placeholder="e.g N1TZDF"
            ></Form.Control>
          </Form.Group>
        </div>
          <div className={`d-flex align-items-center p-3 rounded question mt-4 ${isOpen ? 'open' : ''}`} style={{ backgroundColor: 'white' }} onClick={() => isOpen ? setIsOpen(false) : setIsOpen(true)}>
            <span
              className="me-3 d-flex justify-content-center align-items-center"
              style={{
                height: '1.5rem',
                width: '1.5rem',
                borderRadius: "50%",
                backgroundColor: "#ff6f00",
              }}
            >
              <FontAwesomeIcon icon={faQuestion} style={{ color: "#ffffff" }} />
            </span>
            <span>Where can I find it?</span>
            <span className="ms-auto caret"><FontAwesomeIcon style={{ transform: `${isOpen ? 'rotate(180deg)' : ''}` }} icon={faCaretDown} /></span>
          </div>
          <div className={`mt-2 rounded p-3 d-flex flex-column gap-3` } style={{ backgroundColor: 'white', 'visibility': isOpen ? 'visible' : 'hidden' }}>
            <span>
              A booking reference is a code used by airlines to keep track of individual reservations. You can find it on your e-ticket or email you received from the airline after booking your trip. It can also be referred to as: reservation code, confirmation number, but this is not a ticket number.
            </span>
            <span>Typically this is a six to eight characters booking/confirmation code and may include letters and/or numbers (e.g., 1234JA). Don’t include spaces please.</span>
          </div>
      </div>

      <Formbutton handleContinue={handleContinue} text="Continue" activecontinue={activecontinue}/>
              
    </div>
  );
};

export default Step7;
