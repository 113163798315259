import React from 'react'
import aboutheading from '../assets/headding-img.png'
import About from '../PageComponents/About'
import Clients from '../PageComponents/Clients'
import Footer from '../PageComponents/Footer'
import Header from '../Header'


const Aboutus = () => {
  return (
    <div>
        <Header />
        <div className='about-header'>
            <div className='container'>
                <h1 className='text-center fw-bold'>About us</h1>
                <div className='d-flex justify-content-center mt-5'>
                    <img src={aboutheading} alt="" />
                </div>
            </div>
        </div>
        <About aboutus={true} />
        <Clients/>
        <Footer/>
    </div>
  )
}

export default Aboutus