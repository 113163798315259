import { faEraser, faInfo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import SignatureCanvas from "react-signature-canvas";
import Formbutton from "../Formbutton";
import { useNavigate } from "react-router-dom";
import ReloadReset from "../ReloadReset";

function Step11({ compensationForm, setCompensationForm }) {
  const [activecontinue, setActiveContinue] = useState(false);
  const navigate = useNavigate();

  const [form, setForm] = useState({
    signatureImage: null,
  });

  const signatureCanvasRef = useRef(null);

  const clearSignature = () => {
    signatureCanvasRef.current.clear();
    setActiveContinue(false);
  };

  const isSignatureEmpty = () => {
    return !signatureCanvasRef.current
      .getCanvas()
      .getContext("2d")
      .getImageData(
        0,
        0,
        signatureCanvasRef.current.getCanvas().width,
        signatureCanvasRef.current.getCanvas().height
      )
      .data.some((channel) => channel !== 0);
  };

  useEffect(() => {
    setForm({
      ...form,
      signatureImage: signatureCanvasRef.current.toDataURL(),
      isEmpty: isSignatureEmpty(),
    });
  }, [activecontinue]);

  const handleContinue = async () => {
    const response = await fetch(form.signatureImage);
    const blob = await response.blob();
    const file = new File([blob], 'signature.png', { type: 'image/png' });
    
    setCompensationForm({ ...compensationForm, step11: {
      ...form,
      signatureImage: file,
    } });

    navigate("/funnel/step12");
  };

  ReloadReset(compensationForm, "step10");

  return (
    <div className="main-container">
      <div
        className="d-flex mx-auto flex-column alight-items-center"
        style={{ width: "780px" }}
      >
        <h3>
          Draw your <span className="fw-bold">signature</span> below as per your
          ID and we will start the work to get you paid
        </h3>

        <div className="px-3 py-2 my-3 bg-warning text-center rounded-3">
          Without it, we connot process and file your complain with the airline
        </div>

        <div
          className="rounded p-4 mt-3 d-flex flex-column align-items-end"
          style={{ backgroundColor: "#f3e3d6" }}
        >
          <button className="btn text-danger p-2 fs-5" onClick={clearSignature}>
            <FontAwesomeIcon icon={faEraser}></FontAwesomeIcon> Clear
          </button>
          <div
            className="bg-white p-4 rounded"
            style={{ border: "2px dashed grey" }}
          >
            <SignatureCanvas
              onEnd={() =>
                isSignatureEmpty()
                  ? setActiveContinue(false)
                  : setActiveContinue(true)
              }
              ref={signatureCanvasRef}
              penColor="purple"
              canvasProps={{
                width: "680px",
                height: 200,
                className: "signature-canvas",
              }}
            />
          </div>
        </div>

        <div className="d-flex align-items-center gap-2 mt-4">
          <span
            style={{ width: "25px", height: "25px" }}
            className="fs-6 rounded-circle bg-primary d-flex align-items-center justify-content-center text-white"
          >
            <FontAwesomeIcon icon={faInfo}></FontAwesomeIcon>
          </span>
          <p className="mb-0">
            By sigining you and your fellow passengers agree to the{" "}
            <span className="text-primary fw-bold"> Assignment aggrement</span>.
          </p>
        </div>
      </div>

      <Formbutton
        handleContinue={handleContinue}
        text="Continue"
        activecontinue={activecontinue}
      />
    </div>
  );
}

export default Step11;
