import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { List, ListItem, ListItemText } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationDot } from '@fortawesome/free-solid-svg-icons';
import FlightCompensationApi from '../../Api/FlightCompensationApi';
import Option from './Option';
import Checkbox from './Checkbox';
import Formbutton from '../Formbutton';
import { useNavigate } from 'react-router-dom';
import ReloadReset from '../ReloadReset';

const Step2 = ({ compensationForm, setCompensationForm }) => {
    const navigate = useNavigate();
    const [selectedOption, setSelectedOption] = useState(null);
    const [connectingAirports, setConnectingAirports] = useState(1);
    const [activeContinue, setActiveContinue] = useState(false);
    const [formdata, setFormdata] = useState({
        airport: []
    });
    const [filteredAirports, setFilteredAirports] = useState([]);
    const [focusedAirport, setFocusedAirport] = useState(null);

    const flightCompensationApi = new FlightCompensationApi();

    const handleChange = async (e, index) => {
        const { value } = e.target;
        const updatedAirport = [...formdata.airport];
        updatedAirport[index] = { ...updatedAirport[index], name: value };
        setFormdata({ ...formdata, airport: updatedAirport });

        if (value.length > 1) {
            try {
                const data = await flightCompensationApi.getAirports({ 's': value });
                setFilteredAirports(data.data || []);
            } catch (error) {
                console.error('Error fetching airports:', error);
            }
        } else {
            setFilteredAirports([]);
        }
    };

    const addAirport = () => {
        setConnectingAirports(connectingAirports + 1);
        setActiveContinue(false);
    };

    const handleContinue = () => {
        const { step1 } = compensationForm;
        let step2Data = selectedOption === 'direct'
            ? { 
                connectedAirport: [
                    step1.departure,
                    step1.destination,
                ] 
            }
            : { connectedAirport: formdata.airport };
        step2Data = {
            ...step2Data,
            isDirectFlight: selectedOption === 'direct' ? 'Yes' : 'No'
        }
        setCompensationForm({ ...compensationForm, step2: step2Data, step3: {
            flightIndex: 0,
            affectedFlight: {
                from: step1.departure,
                to: step1.destination,
            }
        }});

        selectedOption === 'direct' ? navigate("/funnel/step4") : navigate('/funnel/step3');
    };

    useEffect(() => {
        console.log(connectingAirports);
        if (selectedOption === 'connecting' && (formdata.airport.length == 0)) {
            setActiveContinue(false);
        }
    }, [formdata, connectingAirports])

    const airportElements = [];
    for (let i = 0; i < connectingAirports; i++) {
        airportElements.push(
            <Form.Group key={i} className="mb-3 connectingform position-relative" controlId={`formBasicEmail-${i}`}>
                <span className='text-danger' style={{ float: 'right', cursor: 'pointer' }} onClick={() =>{
                    let updatedAirport = [...formdata.airport];
                    updatedAirport = updatedAirport.slice(i + 1);

                    setFormdata({ ...formdata, airport: updatedAirport });
                    setConnectingAirports(connectingAirports - 1)

                }}> - Remove</span>
                <Form.Label>{i + 1}. Connecting Airport</Form.Label>
                <Form.Control
                    type="text"
                    name={i}
                    autoComplete='off'
                    onChange={(e) => handleChange(e, i)}
                    onFocus={() => setFocusedAirport(i)}
                    value={formdata.airport[i]?.name || ''}
                    placeholder="e.g Paris"
                />
                {filteredAirports.length > 0 && focusedAirport === i && (
                    <List className='rounded position-absolute' style={{ maxHeight: '12rem', overflowY: 'scroll', border: '2px solid #00000036', backgroundColor: '#fff7ef', top: '6.7rem', zIndex: '20', right: '16px', left: '16px' }}>
                        {filteredAirports.map((suggestion, index) => (
                            <ListItem key={index} onClick={() => {
                                const updatedAirport = [...formdata.airport];
                                updatedAirport[i] = {
                                    name: suggestion.name,
                                    id: suggestion.id,
                                    code: suggestion.iata_code
                                };
                                setFormdata({ ...formdata, airport: updatedAirport });
                                setFilteredAirports([]);
                                setFocusedAirport(null);
                                setActiveContinue(true);
                            }}
                                style={{cursor:'pointer'}}
                            >
                                <FontAwesomeIcon className='me-2' icon={faLocationDot} style={{ color: "#ff6f00" }} />
                                <ListItemText primary={suggestion.name} secondary={suggestion.city + ", " + suggestion.country} style={{cursor:'pointer'}}/>                            
                            </ListItem>
                        ))}
                    </List>
                )}
            </Form.Group>
        );
    }

    ReloadReset(compensationForm, 'step1');
    return (
        <div className='main-container p-5 txt-center d-flex flex-column justify-content-between' style={{ minHeight: '100vh' }}>
            <div className='mx-auto'>
                <h2 className='text-center'>Did you have any connecting flights?</h2>
                <div className='form2 mb-5'>
                    <Option className='formoption'
                        selected={selectedOption === 'direct'}
                        onClick={() => { setSelectedOption('direct'); setActiveContinue(true) }}
                    >
                        <Checkbox selected={selectedOption === 'direct'}>
                            No, it was a direct flight
                        </Checkbox>
                    </Option>
                    <Option className='formoption'
                        selected={selectedOption === 'connecting'}
                        onClick={() => { setSelectedOption('connecting'); setActiveContinue(false); }}
                    >
                        <Checkbox selected={selectedOption === 'connecting'}>
                            Yes, I had to change flights
                        </Checkbox>
                    </Option>
                </div>
                {selectedOption === 'connecting' && (
                    <>
                        {airportElements}
                        <div className='connectingform'>
                            <span className='text-primary' style={{ cursor: 'pointer' }} onClick={addAirport}> + Add connecting stop</span>
                        </div>
                    </>
                )}
            </div>
            <Formbutton handleContinue={handleContinue} activecontinue={activeContinue} />
        </div>
    );
};

export default Step2;
