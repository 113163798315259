import React , {useEffect, useState} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLocationDot, faPlane } from '@fortawesome/free-solid-svg-icons';
import { Button, Form } from 'react-bootstrap';
import ReloadReset from '../ReloadReset';
import { useNavigate } from 'react-router-dom';
import Formbutton from '../Formbutton';
import FlightCompensationApi from '../../Api/FlightCompensationApi';
import { List, ListItem, ListItemText } from '@mui/material';
import moment from 'moment/moment';

const Step6 = ({compensationForm, setCompensationForm}) => {

    const flightCompensationApi = new FlightCompensationApi()

    const navigate = useNavigate();
    const [activecontinue , setActiveContinue] = useState(false);
    const [flightDetails , setFlightDetails] = useState({
        airline: {
            id:'',
            name:'',
        },
        departureDate: '',
        flightNumber: '',
        numberOfPerson: 1
    });
    const [compensation, setCompensation] = useState({
        currency: 'EUR',
        compensation: 0
    });
    const [loading, setLoading] = useState({
        airline: false
    });

    const [filteredSuggestions, setFilteredSuggestions] = useState([]);

    const handleChange = (e) =>{
        setFlightDetails({...flightDetails, [e.target.name]: e.target.value})
    }   

    const handleContinue = ()=>{
        setCompensationForm({...compensationForm, step6: flightDetails})

        navigate('/funnel/step7')

    }

    useEffect(()=> {
        if(flightDetails.airline.id !== '' && flightDetails.departureDate !== '' && flightDetails.flightNumber !== '' && flightDetails.numberOfPerson !== ''){
            setActiveContinue(true)
        }else{
            setActiveContinue(false)
        }
    },[flightDetails])

    const fetchAirlines = async (value) => {
        try{
          setLoading({
            ...loading,
            airline: true
          })
          const data = await flightCompensationApi.getAirlines({
            's': value
          });
          setLoading({
            ...loading,
            airline: false
          })
          return data;
        }
        catch{
          console.log('Error fetching data:');
          setLoading({
            ...loading,
            airline: false
          })
        }
      }
    

    const numberOfPassengers = [];
    for (let i = 1; i <= 10; i++) {
        numberOfPassengers.push(<option key={i} value={i}>{i}</option>);
    }
    
    ReloadReset(compensationForm, 'step5');
  return (
    <>
        <div className='main-container'>
            <div className='d-flex mx-auto flex-column alight-items-center'>
                <h3>What are your <b>flight details</b>?</h3>
                <div className="rounded p-4" style={{backgroundColor: "#f3e3d6"}}>
                    <p className='d-flex align-items-center mb-4' style={{borderBottom: '1px dashed grey'}}>{compensationForm?.step3?.affectedFlight?.from.name} &nbsp; <FontAwesomeIcon icon={faPlane} style={{color:'grey'}} /> &nbsp; { compensationForm?.step3?.affectedFlight?.to.name }</p>
                    
                    <div className='row'>
                        <Form.Group  className="mb-3 col-12 position-relative" controlId="formBasicEmail">
                            <Form.Label>Specify the airline company</Form.Label>
                            <Form.Control  autoComplete='off' type="text" name='airlineCompany' className='relative'  value={flightDetails.airline.name} onChange={async (e) => {

                                setFlightDetails({ ...flightDetails, airline: {id: '', name: e.target.value} })
                                    fetchAirlines(e.target.value).then(data => {

                                    setFilteredSuggestions(data.data);

                                });


                            }} placeholder="e.g PIA" ></Form.Control>
                            {loading.airline && <div className='rounded position-absolute d-flex align-items-center justify-content-center fs-6' style={{ height: '4rem', border: '2px solid #00000036', backgroundColor: '#fff7ef', top: '5.9rem', zIndex: '20', right: '12px', left: '12px' }}>Loading...</div>}

                            {
                                !loading.airline && filteredSuggestions.length > 0 && 
                                    
                                    <List className='rounded position-absolute' style={{ maxHeight: '12rem', overflowY: 'scroll', border: '2px solid #00000036', backgroundColor: '#fff7ef', top: '5.9rem', zIndex: '20', right: '12px', left: '12px' }}>
                                    {filteredSuggestions.map((suggestion, index) => (
                                        <ListItem className='align-items-start'  key={index} onClick={() => { 
                                            setFilteredSuggestions([]);
                                            setFlightDetails({ ...flightDetails, airline: {id: suggestion.id, name: suggestion.name }});
                                            setActiveContinue(true);
                                        }}>
                                        <FontAwesomeIcon className='me-2' icon={faLocationDot} style={{ color: "#ff6f00",marginTop:'0.7rem' }} />
                                        <ListItemText primary={suggestion.name} style={{cursor:'pointer'}}/>
                                        </ListItem>
                                    ))}
                                    </List>
                                
                                }
                        </Form.Group>
                        <Form.Group className="mb-3 col-12 col-md-4 position-relative" controlId="formBasicEmail">
                            <Form.Label>Departure date</Form.Label>
                            <Form.Control  autoComplete='off' type="date" max={moment().format("YYYY-MM-DD")} name='departureDate'   value={flightDetails.departureDate} onChange={handleChange}  >
                            </Form.Control>
                        </Form.Group>
                        <Form.Group className="mb-3 col-12 col-md-4 position-relative" controlId="formBasicEmail">
                            <Form.Label>Flight number</Form.Label>
                            <Form.Control  autoComplete='off' type="number" name='flightNumber'   value={flightDetails.flightNumber} onChange={handleChange} placeholder="e.g 1234" >
                            </Form.Control>
                        </Form.Group>
                        <Form.Group className="mb-3 col-12 col-md-4 position-relative" controlId="formBasicEmail">
                            <Form.Label>Number of passengers</Form.Label>
                            <Form.Select 
                                name="numberOfPerson" 
                                value={flightDetails.numberOfPerson} 
                                onChange={handleChange}
                            >
                                <option value="" disabled>Select number of passengers</option>
                                {numberOfPassengers}
                            </Form.Select>
                        </Form.Group>
                    </div>

                </div>
            </div>
        </div>
        <Formbutton handleContinue={handleContinue} text="Check My Compensation" activecontinue={activecontinue}/>
    
    </>

  )
}

export default Step6