import logo from './logo.svg';
import './App.css';
import './Stylesheet/about.css';
import { Route, Routes, useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import Step1 from './components/Steps/Step1';
import Step2 from './components/Steps/Step2';
import { useEffect, useState } from 'react';
import Step3 from './components/Steps/Step3';
import Step4 from './components/Steps/Step4';
import Step5 from './components/Steps/Step5';
import Step6 from './components/Steps/Step6';
import Step7 from './components/Steps/Step7';
import Sidebar from './components/Sidebar';
import Topbar from './components/Topbar';
import FormStatus from './components/FormStatus';
import Faq from './components/Pages/Faq';
import Home from './components/Pages/Home';
import Aboutus from './components/Pages/Aboutus';
import Header from './components/Header';
import Step8 from './components/Steps/Step8';
import Step9 from './components/Steps/Step9';
import Step10 from './components/Steps/Step10';
import Step11 from './components/Steps/Step11';
import Step12 from './components/Steps/Step12';
import Step13 from './components/Steps/Step13';
import Step14 from './components/Steps/Step14';
import Submit from './components/Steps/Submit';

function App() {
  const [compensationForm, setCompensationForm] = useState({});
  document.title = process.env.REACT_APP_TITLE

  const navigate = useNavigate();

  useEffect(() => {
    console.log(compensationForm);
  }, [compensationForm]);

  // console.log(compensationForm);
  return (
    <div className=''>
      {/* <Topbar/> */}
      <Routes>
        <Route path='/' element={ <Home compensationForm={compensationForm} setCompensationForm={setCompensationForm} />} />
        <Route path='/about' element={<Aboutus />} />
        <Route path='/faq' element={<Faq />} />
        <Route path="funnel/*" element={
          <FormStatus compensationForm={compensationForm} setCompensationForm={setCompensationForm}>
            <Routes>
              <Route path="/" index element={<Step1 compensationForm={compensationForm} setCompensationForm={setCompensationForm} />} />
              <Route path="/step2" element={<Step2 compensationForm={compensationForm} setCompensationForm={setCompensationForm} />} />
              <Route path="/step3" element={<Step3 compensationForm={compensationForm} setCompensationForm={setCompensationForm} />} />
              <Route path="/step4" element={<Step4 compensationForm={compensationForm} setCompensationForm={setCompensationForm} />} />
              <Route path="/step5" element={<Step5 compensationForm={compensationForm} setCompensationForm={setCompensationForm} />} />
              <Route path="/step6" element={<Step6 compensationForm={compensationForm} setCompensationForm={setCompensationForm} />} />
              <Route path="/step7" element={<Step7 compensationForm={compensationForm} setCompensationForm={setCompensationForm} />} />
              <Route path="/step8" element={<Step8 compensationForm={compensationForm} setCompensationForm={setCompensationForm} />} />
              <Route path="/step9" element={<Step9 compensationForm={compensationForm} setCompensationForm={setCompensationForm} />} />
              <Route path="/step10" element={<Step10 compensationForm={compensationForm} setCompensationForm={setCompensationForm} />} />
              <Route path="/step11" element={<Step11 compensationForm={compensationForm} setCompensationForm={setCompensationForm} />} />
              <Route path="/step12" element={<Step12 compensationForm={compensationForm} setCompensationForm={setCompensationForm} />} />
              <Route path="/step13" element={<Step13 compensationForm={compensationForm} setCompensationForm={setCompensationForm} />} />
              <Route path="/step14" element={<Step14 compensationForm={compensationForm} setCompensationForm={setCompensationForm} />} />
              <Route path="/submit" element={<Submit compensationForm={compensationForm} setCompensationForm={setCompensationForm} />} />
            </Routes>
          </FormStatus>
        } />
      </Routes>
    </div>
    // <div className="App">
    //   <header className="App-header">
    //     <img src={logo} className="App-logo" alt="logo" />
    //     <p>
    //       Edit <code>src/App.js</code> and save to reload.
    //     </p>
    //     <a
    //       className="App-link"
    //       href="https://reactjs.org"
    //       target="_blank"
    //       rel="noopener noreferrer"
    //     >
    //       Learn React
    //     </a>
    //   </header>
    // </div>

  );
}

export default App;