import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import youtube_bg from '../assets/youtube_bg.png'
import aboutusimg from '../assets/aboutus.jpg'

const About = ({aboutus}) => {
  return (
    <div className="about__wrapper">
      <div className="container">
        <div className="outer_about">
          <div className="title">
            <h2 className="fw-bold">About {process.env.REACT_APP_TITLE}</h2>
          </div>
          <div className="about__double flex-column-reverse flex-lg-row d-flex align-items-center justify-content-between">
            <div className="about__desc">
              <p>
                <b>
                {process.env.REACT_APP_TITLE} is an airline compensation company with deep
                  expertise in consumer advocacy for air passengers.
                </b>
                We fight for air passenger rights in cases of flight disruptions
                all over the world. Our mission is to ensure that air passengers
                are fairly compensated for the inconvenience and frustration
                caused by delays, cancellations, overbooking and baggage
                problems.
              </p>
              <p>
                Why should airlines deny compensation to passengers for flight
                disruptions, when the average pay of an airline’s CEO is almost
                EUR 2.8 million? We work to defend your rights as an airline
                passenger because travel should be comfortable, and everyone
                should be treated fairly.
              </p>
              <p>
                That’s why we simplified the process of getting flight
                compensation from airlines for those who lack the time, energy,
                or resources to do so themselves. With legal partners in
                countries around the world, we enforce our clients’ flight
                claims in courts and advocate for their passenger rights before
                civil aviation authorities. To further our vision, our team of
                experts lobbies for improved airline regulations that will
                protect air passengers worldwide.
              </p>
              <div className="button-wrapper d-flex align-items-center justify-content-start">
                <a
                  href="#"
                  aria-label="About us"
                  className="read-more d-inline-flex align-items-center gap-3 justify-content-start"
                >
                  Read more{" "}
                  <span className="d-inline-flex align-items-center">
                    <FontAwesomeIcon icon={faArrowRight} />
                  </span>
                </a>
              </div>
            </div>
            {!aboutus &&<div className="about__video ms-0 ms-lg-auto">
              <div className="video__inner position-relative d-flex align-items-center justify-content-center video_play_block--show">
                <a
                  href="https://youtu.be/5-hfZzf8LmY"
                  rel="noreferrer"
                  target="_blank"
                >
                  <img
                    loading="lazy"
                    decoding="async"
                    width="621"
                    height="351"
                    className="js-init img-fluid"
                    src={youtube_bg}
                    alt="Check your flight for free"
                  />
                </a>
              </div>
            </div>}
            {aboutus && 
              <div className="ms-5 aboutus-img">
                  <img src={aboutusimg}  alt="" />
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
