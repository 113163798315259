import React, { useEffect, useState } from 'react'
import Form from 'react-bootstrap/Form';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLocationDot, faPlaneArrival, faPlaneDeparture } from '@fortawesome/free-solid-svg-icons';

import { List, ListItem, ListItemText } from '@mui/material';

import FlightCompensationApi from '../../Api/FlightCompensationApi';
import Formbutton from '../Formbutton';

const Step1 = ({ compensationForm, setCompensationForm }) => {

  const flightCompensationApi = new FlightCompensationApi();

  const navigate = useNavigate();
  const [formdata, setFormdata] = useState({
    departure: {
      id: null,
      name: '',
      code: ''
    },
    destination: {
      id: null,
      name: '',
      code: ''
    }
  });
  
  const handleContinue = () => {
    setCompensationForm({ ...compensationForm, step1: formdata });
    navigate("/funnel/step2");
  };
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [departureFocus, setDepartureFocus] = useState(false)
  const [destinationFocus, setDestinationFocus] = useState(false)
  const [activeContinue, setActiveContinue] = useState(false);
  const [loading, setLoading] = useState(false);
  useEffect(()=>{
    if(formdata.departure.id !== null && formdata.destination.id !== null){
      setActiveContinue(true)
    }
  },[formdata])
  const fetchAirports = async (value) => {
    try{
      setLoading(true)
      const data = await flightCompensationApi.getAirports({
        's': value
      });
      setLoading(false)
      return data;
    }
    catch{
      console.log('Error fetching data:');
      setLoading(false)
    }
  }

  return (
    <>
      <div className='main-container mt-5' >
        <Form autoComplete='off' className='d-flex mx-auto flex-column justify-content-between form1'  >
          <Form.Group className='row'>
            <Form.Group className="col-md-6 position-relative" controlId="formBasicEmail">
              <Form.Label>Departure airport</Form.Label>
              <Form.Control type="text" name='departure.name' onFocus={() => {
                setDestinationFocus(false);
                setDepartureFocus(true);
              }} required className='relative icon-input' value={formdata.departure.name} onChange={async (e) => {

                setFormdata({ ...formdata, departure: { id: null, name: e.target.value, code: '' } })
                fetchAirports(e.target.value).then(data => {
      
                  setFilteredSuggestions(data.data);

                });


              }} placeholder="e.g Paris" >
              </Form.Control>
              <FontAwesomeIcon className='position-absolute' style={{ right: '20px', bottom: '20%', color: 'grey' }} icon={faPlaneDeparture} />
              {loading && departureFocus && <div className='rounded position-absolute d-flex align-items-center justify-content-center fs-6' style={{ height: '4rem', border: '2px solid #00000036', backgroundColor: '#fff7ef', top: '5.9rem', zIndex: '20', right: '12px', left: '12px' }}>Loading...</div>}
              { !loading && departureFocus && filteredSuggestions.length > 0 && 
                
                <List className='rounded position-absolute' style={{ maxHeight: '12rem', overflowY: 'scroll', border: '2px solid #00000036', backgroundColor: '#fff7ef', top: '5.9rem', zIndex: '20', right: '12px', left: '12px' }}>
                  {filteredSuggestions.map((suggestion, index) => (
                    <ListItem className='align-items-start'  key={index} onClick={() => { 
                        setFormdata({ ...formdata, departure: {id: suggestion.id, name: suggestion.name, code: suggestion.iata_code }});
                        setDepartureFocus(false);
                        setFilteredSuggestions([]);
                      }}>
                      <FontAwesomeIcon className='me-2' icon={faLocationDot} style={{ color: "#ff6f00",marginTop:'0.7rem' }} />
                      <ListItemText primary={suggestion.name} secondary={suggestion.city + ", " + suggestion.country} style={{cursor:'pointer'}}/>                    
                    </ListItem>
                  ))}
                </List>
              
              }
            </Form.Group>

            <Form.Group className="position-relative col-md-6" controlId="formBasicPassword">
              <Form.Label>Final destination airport</Form.Label>
              <Form.Control type="text" className='relative icon-input' name='destination,name' required onFocus={() => {
                setDestinationFocus(true);
                setDepartureFocus(false);
              }} value={formdata.destination.name} onChange={async(e) => {

                                  
                  setFormdata({ ...formdata, destination: { id: null, name: e.target.value, code: '' } })
                  fetchAirports(e.target.value).then(data => {

                    setFilteredSuggestions(data.data);

                  });


              }} placeholder="e.g Berlin" />
              <FontAwesomeIcon className='position-absolute' style={{ right: '20px', bottom: '20%', color: 'grey' }} icon={faPlaneArrival} />
              {loading && destinationFocus && <div className='rounded position-absolute d-flex align-items-center justify-content-center fs-6' style={{ height: '4rem', border: '2px solid #00000036', backgroundColor: '#fff7ef', top: '5.9rem', zIndex: '20', right: '12px', left: '12px' }}>Loading...</div>}
              {!loading && destinationFocus && filteredSuggestions.length > 0 && (
                <List className='rounded position-absolute' style={{ maxHeight: '12rem', overflowY: 'scroll', border: '2px solid #00000036', backgroundColor: '#fff7ef', top: '5.9rem', zIndex: '20', right: '12px', left: '12px' }}>
                  {filteredSuggestions.map((suggestion, index) => (
                    <ListItem className='align-items-start' key={index} onClick={() => { 
                      setFormdata({ ...formdata, destination: {id: suggestion.id, name: suggestion.name, code: suggestion.iata_code }}) 
                      setFilteredSuggestions([]);
                      setDestinationFocus(false);
                      }} style={{alignItems:'flex-start !important'}}>
                      <FontAwesomeIcon className='me-2' icon={faLocationDot} style={{ color: "#ff6f00",marginTop:'0.7rem' }} />
                      <ListItemText primary={suggestion.name} secondary={suggestion.city + ", " + suggestion.country} style={{cursor:'pointer'}}/>
                    </ListItem>
                  ))}
                </List>
              )}
            </Form.Group>
          </Form.Group>
        </Form>
        <Formbutton handleContinue={handleContinue} backDisable={true} activecontinue={activeContinue} />
      </div>
    </>
  )
}

export default Step1