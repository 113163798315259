import React, { useEffect, useMemo, useState } from "react";
import ReloadReset from "../ReloadReset";
import Formbutton from "../Formbutton";
import { Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import PhoneInputWithCountrySelect, { isValidPhoneNumber } from "react-phone-number-input";
import 'react-phone-number-input/style.css'
import countryList from "react-select-country-list";
import Select from 'react-select';




function Step10({ compensationForm, setCompensationForm }) {
  const navigate = useNavigate();
  const [activecontinue, setActiveContinue] = useState(false);
  const [form, setForm] = useState({
    address: null,
    city: null,
    postal_code: null,
    country: null,
    mobile_number: null
  });

  const countries = useMemo(() => countryList().getData(), [])

  const changeCountry = value => {
    console.log('====================================');
    console.log(value.label);
    console.log('====================================');
    setForm({
      ...form,
      country: value.label
    })
  }


  useEffect(() => {
    if (form.address && form.city && form.country && form.postal_code && (form.mobile_number ? isValidPhoneNumber(form.mobile_number) : null))
    {
        setActiveContinue(true);
    } else {
        setActiveContinue(false);
    }
  }, [form])


  const handleContinue = () => {

    setCompensationForm({...compensationForm, step10: form})

    navigate('/funnel/step11')
  };


  ReloadReset(compensationForm, 'step9');

  return (
    <div className="main-container">
      <div
        className="d-flex mx-auto flex-column alight-items-center"
        style={{ width: "780px" }}
      >
        <h3>Add your address</h3>

        <div
          className="rounded p-4 mt-3"
          style={{ backgroundColor: "#f3e3d6" }}
        >
            <Form.Group
                className="mb-3 col-12 position-relative"
                controlId="address"
              >
                <Form.Label>Address</Form.Label>
                <Form.Control
                    type="text"
                    name="address"
                    value={form.address}
                    onChange={(e) =>
                    setForm({
                        ...form,
                        address: e.target.value,
                    })
                    }
                    placeholder="e.g 52 Rue Condorcet"
                ></Form.Control>
            </Form.Group>
          <div className="row">
            <div className="col-lg-6">
              <Form.Group
                className="mb-3 col-12 position-relative"
                controlId="city"
              >
                <Form.Label>City</Form.Label>
                <Form.Control
                  type="text"
                  name="city"
                  value={form.city}
                  onChange={(e) =>
                    setForm({
                      ...form,
                      city: e.target.value,
                    })
                  }
                  placeholder="e.g London"
                ></Form.Control>
              </Form.Group>
            </div>
            <div className="col-lg-6">
            <Form.Group
                className="mb-3 col-12 position-relative"
                controlId="city"
              >
                <Form.Label>Postal Code</Form.Label>
                <Form.Control
                  type="text"
                  name="postal_code"
                  value={form.postal_code}
                  onChange={(e) =>
                    setForm({
                      ...form,
                      postal_code: e.target.value,
                    })
                  }
                  placeholder="Postal Code"
                ></Form.Control>
              </Form.Group>
            </div>

          </div>

          <Form.Group
            className="mb-3 col-12 position-relative"
            controlId="country"
            >
            <Form.Label>Country</Form.Label>
            <Select
                name="country"
                onChange={changeCountry}
                options={countries}
                placeholder="e.g United Kingdom"
            ></Select>
          </Form.Group>
          <Form.Group
            className="mb-3 col-12 position-relative"
            controlId="phone_number"
            >
            <Form.Label>Phone Number</Form.Label>
            <PhoneInputWithCountrySelect
                name="phone_number"
                value={form.mobile_number}
                className="form-control"
                onChange={(value) =>
                setForm({
                    ...form,
                    mobile_number: value,
                })
                }
                
            />
          </Form.Group>
        </div>
      </div>

      <Formbutton handleContinue={handleContinue} text="Continue" activecontinue={activecontinue}/>

    </div>
  );
}

export default Step10;
