import { faFacebook, faLinkedin, faTwitter } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {
  return (
    <div className='footer'>
        <div className='container'>
            <div className='d-flex flex-sm-row flex-column justify-content-between align-items-center'>
                <p class="m-0">Copyright © <Link to='/' >{process.env.REACT_APP_TITLE}</Link> 2024</p>
                <div className='social_links'>
                    <FontAwesomeIcon icon={faFacebook} />
                    <FontAwesomeIcon icon={faTwitter} />
                    <FontAwesomeIcon icon={faLinkedin} />
                </div>
            </div>
        </div>
    </div>
  )
}

export default Footer