import { faBriefcase, faCircleCheck, faEarthAsia, faHandshake } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

const InfoPart = () => {
  return (
    <div className='info-part py-4 '>
            <div className='container'>
                <div className='d-flex align-items-center gap-3 gap-lg-0 flex-column flex-lg-row justify-content-between text-white fw-bold'>
                    <div className='d-flex align-items-center gap-3'><FontAwesomeIcon icon={faHandshake} /> Trusted by 260,000+ clients</div>
                    <div className='d-flex align-items-center gap-3'><FontAwesomeIcon icon={faBriefcase} /> 7+ years of experience</div>
                    <div className='d-flex align-items-center gap-3'><FontAwesomeIcon icon={faCircleCheck} /> 98% success rate</div>
                    <div className='d-flex align-items-center gap-3'><FontAwesomeIcon icon={faEarthAsia} /> Worldwide coverage</div>
                </div>
            </div>
        </div>
  )
}

export default InfoPart