import React from 'react'
import line from './assets/stepsReviewDashes.svg'
import check from './assets/checkactive.svg'


const StepReview = ({status , title, description , view}) => {
  return (
    <>
    { view === 'sidebar' && <div className={`steps_review ${status? status:''}`}>
        <div className='leftPart'>
            <span className='d-flex align-items-center justify-content-center'>
                {/* <img src={check} alt="check" /> */}
                <svg width="10" height="9" viewBox="0 0 10 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.75 4.50187L3.58 7.33187L9.25 1.67188" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>

            </span>
            {/* <img className='dotted-line' src={line} alt="dotted" /> */}
            <svg width="2" height="40" viewBox="0 0 2 40" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 1L1 4" stroke="#ffffff77" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path><path d="M1 8L1 11" stroke="#ffffff77" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path><path d="M1 15L1 18" stroke="#ffffff77" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path><path d="M1 22L1 25" stroke="#ffffff77" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path><path d="M1 29L1 32" stroke="#ffffff77" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path><path d="M1 36L1 39" stroke="#ffffff77" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path></svg>
        </div>
        <div className='rightPart'>
            <h5>{title}</h5>
            <p>{description}</p>
        </div>
    </div>}
    {
        view !=='sidebar' && 
        <div className={`top-step-elem ${status? status:""}`}>
            
            <p>{status ==='filled' && <span> <svg width="10" height="9" viewBox="0 0 10 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.75 4.50187L3.58 7.33187L9.25 1.67188" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                </svg> </span>}{title}</p>
        </div>
    }
    </>
  )
}

export default StepReview