import React from "react";

function Submit({  }) {
  return (
    <div className="main-container">
      <div
        className="d-flex mx-auto flex-column alight-items-center"
        style={{ width: "780px" }}
      >
        <h3>
          Your request is submitted to zendesk for review.
        </h3>
      </div>
    </div>
  );
}

export default Submit;
