import axios from "axios";
import {setWithExpiry, getWithExpiry} from "../Helpers/Helper.js";

class FlightCompensationApi {

    constructor() {

        this.baseUrl = process.env.REACT_APP_BACKEND_BASE_URL;
        this.client_id = process.env.REACT_APP_BACKEND_CLIENT_ID;
        this.client_secret = process.env.REACT_APP_BACKEND_CLIENT_SECRET;
    }

    /**
     * Get Access Token
     */
    async getAccessToken(newToken = false)
    {

        if (getWithExpiry('access_token') !== null && ! newToken) return getWithExpiry('access_token');

        let tokenInfo = await axios.post(this.baseUrl + '/oauth/token', {
            grant_type: 'client_credentials',
            client_id: this.client_id,
            client_secret: this.client_secret
        });


        let accessToken = tokenInfo.data.access_token;
        let expiresIn = tokenInfo.data.expires_in;

        setWithExpiry('access_token', accessToken, expiresIn);
        
        return accessToken;
    }

    /**
     * Get Airlines
     */
    async getAirlines(queryParams)
    {

        let accessToken = await this.getAccessToken();

        try {

            let response = await axios.get(this.baseUrl + '/api/v1/airlines', {
                params: queryParams,
                headers: {
                    'Authorization': 'Bearer ' + accessToken,
                    'Accept': 'application/json'
                }
            });
            return response.data.data;

        } catch (error) {
            console.log(error);
            if (error.response.status == 401)
            {
                await this.getAccessToken(true); // Refresh token
                return this.getAirlines();
            }
        }


    }

    
    /**
     * Get Airports
     */
    async getAirports(queryParams)
    {

        let accessToken = await this.getAccessToken();

        try {

            let response = await axios.get(this.baseUrl + '/api/v1/airports', {
                params: queryParams,
                headers: {
                    'Authorization': 'Bearer ' + accessToken,
                    'Accept': 'application/json'
                }
            });
            return response.data.data;

        } catch (error) {
            console.log(error);
            if (error.response.status == 401)
            {
                await this.getAccessToken(true); // Refresh token
                return this.getAirports();
            }
        }


    }

    /**
     * Get compensation
     * 
     */
    async calculateCompesation(data) {

        let accessToken = await this.getAccessToken();

        let response = await axios.post(this.baseUrl + '/api/v1/calculate-compensation', data, {
           
            headers: {
                'Authorization': 'Bearer ' + accessToken,
                'Accept': 'application/json'
            }
        });

        return response.data.data;

    }

    /**
     * Get distance
     */
    async calculateFlightDistance(departure_flight, destination_flight) {

        let accessToken = await this.getAccessToken();
        let data = {
            departure_flight: departure_flight,
            destination_flight: destination_flight
        };

        try {
            let response = await axios.get(this.baseUrl + '/api/v1/calculate-flight-distance', {
                params: data,
                headers: {
                    'Authorization': 'Bearer ' + accessToken,
                    'Accept': 'application/json'
                }
            });

            return response.data.data;
        } catch (error) {
            console.log(error);
            if (error.response.status == 401)
            {
                await this.getAccessToken(true); // Refresh token
                return this.calculateFlightDistance();
            }
        }


    }

    /**
     * Create zendesk ticket
     */
    async submitCaseToZendesk(data, documentToken, ticket_identifier) {

        let accessToken = await this.getAccessToken();

        try {
            let response = await axios.post(this.baseUrl + '/api/v1/submit-to-zendesk', {...data, 'document_token': documentToken, 'unique_ticket_id': ticket_identifier}, {
                headers: {
                    'Authorization': 'Bearer ' + accessToken,
                    'Accept': 'application/json'
                }
            });

            return response.data;
        } catch (error) {
            console.log(error);
            if (error.response.status == 401)
            {
                await this.getAccessToken(true); // Refresh token
                return this.submitCaseToZendesk();
            }
        }

    }

    /**
     * Upload files to zendesk
     * 
     */
    async uploadFilesToZendesk(formData) {

        let accessToken = await this.getAccessToken();

        try {
            let response = await axios.post(this.baseUrl + '/api/v1/file-to-zendesk', formData, {
                headers: {
                    'Authorization': 'Bearer ' + accessToken,
                    'Accept': 'application/json',
                    'Content-Type': 'multipart/form-data'
                }
            });

            return response.data;
        } catch (error) {
            console.log(error);
            if (error.response.status === 401)
            {
                await this.getAccessToken(true); // Refresh token
                return this.uploadFilesToZendesk();
            }
        }

    }
}

export default FlightCompensationApi;