import React, { useEffect, useState } from 'react'
import styled from 'styled-components';

import Form from 'react-bootstrap/Form';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlane } from '@fortawesome/free-solid-svg-icons';
import Option from './Option';
import Checkbox from './Checkbox';
import ReloadReset from '../ReloadReset';
import Formbutton from '../Formbutton';

  


const Step3 = ({compensationForm, setCompensationForm}) => {
    const [selectedOption, setSelectedOption] = useState({});
    const [flightIndex, setFlightIndex] = useState(0);
    const [activecontinue , setActiveContinue] = useState(false);
    const navigate = useNavigate();

    const handleContinue = () => {
        setCompensationForm({...compensationForm, step3: {
            flightIndex: flightIndex,
            affectedFlight: selectedOption
        }})
        navigate('/funnel/step4')
    }
    
    const [airportList , setAirportList] = useState([]);
    
    useEffect(()=> {
        const { step1, step2 } = compensationForm;
        const updatedAirportList = [
            step1.departure,
            ...step2.connectedAirport, 
            step1.destination
        ];
        setAirportList(updatedAirportList);
    },[])
    
    ReloadReset(compensationForm, 'step2');
  return (
    <div className='main-container d-flex flex-column p-5 justify-content-between' style={{minHeight:'100vh'}}>
        { compensationForm?.step2 !=='direct' && 
        <div className='d-flex row mx-auto flex-column align-items-center'>
            <h1 className='text-center'>Which flight was disrupted and affected your travel?</h1>
            <div className='form2 row row-gap-3 airport-list mb-5'>
                {/* {compensationForm.step2.map((item)=>{
                    return( */}

                {airportList.map((airport, i) => {
                    if (i < airportList.length - 1) {
                        return (
                        <Option
                            key={i + 1}
                            className="formoption col-5"
                            selected={flightIndex == i}
                            onClick={() => {
                                setActiveContinue(true);
                                setFlightIndex(i);
                                setSelectedOption({
                                    from: {
                                        id: airport.id,
                                        name: airport.name,
                                        code: airport.code,
                                    },
                                    to: {
                                        id: airportList[i + 1].id,
                                        name: airportList[i + 1].name,
                                        code: airportList[i + 1].code,
                                    }
                                })
                            }}
                        >
                            <Checkbox selected={flightIndex == i}>
                            <span>{airport.name}</span> &nbsp; <FontAwesomeIcon icon={faPlane} style={{color:'#ff6f00'}} />  &nbsp; <span>{airportList[i + 1].name}</span>
                            </Checkbox>
                        </Option>
                        );
                    } else {
                        return null;
                    }
                })}
            {/* <Option className='formoption'
                selected={selectedOption === `${departure} to ${middlePoint}`}
                onClick={() =>{ setActiveContinue(true); setSelectedOption(`${departure} to ${middlePoint}`)}}
            >

                <Checkbox selected={selectedOption === `${departure} to ${middlePoint}`}>
                {departure} &nbsp; <FontAwesomeIcon icon={faPlane} style={{color:'grey'}} />  &nbsp; {middlePoint}
                </Checkbox>
            </Option> */}
                    {/* )
                })} */}
            {/* <Option className='formoption'
                selected={selectedOption === `${middlePoint} to ${destination}`}
                onClick={() =>{ setActiveContinue(true); setSelectedOption(`${middlePoint} to ${destination}`)}}
            >
                <Checkbox selected={selectedOption === `${middlePoint} to ${destination}`}>
                {middlePoint}  &nbsp; <FontAwesomeIcon icon={faPlane} style={{color:'grey'}} />  &nbsp; {destination}
                </Checkbox>
            </Option> */}
            </div>
        </div>}
        <Formbutton handleContinue={handleContinue} activecontinue={activecontinue}/>
    </div>
  )
}

export default Step3