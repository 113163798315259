import React, { useEffect, useState } from 'react'
import ReloadReset from '../ReloadReset';
import { useNavigate } from 'react-router-dom';
import Formbutton from '../Formbutton';
import Option from './Option';
import Checkbox from './Checkbox';
import { Form } from 'react-bootstrap';

function Step9({ compensationForm, setCompensationForm }) {

  const navigate = useNavigate();
  const [activecontinue, setActiveContinue] = useState(false);
  const [form, setForm] = useState({
    with_passengers: 'No',
    passengers: []
  });


  const setPassengerValue = (property, value, index) => {
    let passengers = form.passengers;
    passengers[index][property] = value

    setForm({
        ...form,
        passengers
    })
  }

  const checkForNullValue = () => {

    if (form.passengers.length == 0) {
        setActiveContinue(true);
        return;
    }

    console.log(form);
    for (let passenger of form.passengers)
    {
        if ((passenger.first_name && passenger.last_name && (passenger.email || passenger.under_18 == true) && (passenger.booking_reference || passenger.same_booking_reference == true)) || form.with_passengers == 'No')
        {
            setActiveContinue(true);
        } else {
            setActiveContinue(false);
            break;
        }
    }

  }

  useEffect(() => {
    checkForNullValue();
  }, [form])

  useEffect(() => {

    let passengers = [];

    for (let index = 1; index < compensationForm.step6.numberOfPerson; index++) {
        let passenger = {
            under_18: false,
            first_name: null,
            last_name: null,
            email: null,
            same_booking_reference: true,
            booking_reference: null
        }

        passengers.push(passenger)
    }
    setForm({
        ...form,
        passengers: passengers
    })

  }, []);

  const formatForm = () => {

    setForm({
        ...form,
        passengers: {...form.passengers.map(passenger => {
            
            return {
                ...passenger,
                email: passenger.under_18 ? compensationForm.step8.filerDetails.email : passenger.email,
                booking_reference: passenger.same_booking_reference ? compensationForm.step7.booking_reference_number: passenger.booking_reference,
            }
            
        })}
    })

  }


  const handleContinue = () => {

    formatForm();

    setCompensationForm({...compensationForm, step9: form})

    navigate('/funnel/step10')
  };


  ReloadReset(compensationForm, 'step8');

  return (
    <div className='main-container'>
        <div className='d-flex mx-auto flex-column alight-items-center' style={{ width: "780px" }}>
            <div className='px-3 py-2 bg-warning text-center rounded-3'>
                You can claim compensation for <span>family & friends</span> who flew with you.
            </div>

            
            <div className='row my-4'>
                <div className='col-lg-6'>
                    <Option className='formoption w-100 mx-0'
                        selected={form.with_passengers === 'No'}
                        onClick={() => setForm({
                            ...form,
                            with_passengers: 'No'
                        })}
                    >
                        <Checkbox selected={form.with_passengers === 'No'}>
                            <span className='fw-bold '>No</span> I was traveling alone
                        </Checkbox>
                    </Option>
                </div>
                <div className='col-lg-6'>
                    <Option className='formoption w-100 mx-0'
                        selected={form.with_passengers === 'Yes'}
                        onClick={() => setForm({
                            ...form,
                            with_passengers: 'Yes'
                        })}
                    >
                        <Checkbox selected={form.with_passengers === 'Yes'}>
                            <span className='fw-bold '>Yes</span> I'll include passenengers
                        </Checkbox>
                    </Option>
                </div>
            </div>

            <div className='d-flex flex-column gap-3'>

                {
                   form.with_passengers == 'Yes' && form.passengers.map((passenger, index) => {
                        return (
                            <div className="rounded p-4 mt-3" style={{ backgroundColor: "#f3e3d6" }} key={'passenger-' + index}>

                                <div className='mb-4' style={{borderBottom: '1px dashed grey'}}>
                                    <h4 className='mb-4 fw-bold'>Passenger {index + 2}</h4>
                                    <Form.Group
                                        className="mb-3 col-12 d-flex position-relative"
                                        controlId={ `passenger-${index}-under18` }
                                    >
                                        <Form.Switch
                                        name={ `passenger-${index}-under18` }
                                        value={passenger.under_18}
                                        onChange={(e) => setPassengerValue('under_18', e.target.checked ? true : false, index)}
                                        >
                                            
                                        </Form.Switch>
                                        <Form.Label className='ms-2'>This person is under 18</Form.Label>
                                    </Form.Group>
                                </div>
                                
                                <div className='row'>
                                    <div className='col-lg-6'>
                                        <Form.Group
                                            className="mb-3 col-12 position-relative"
                                            controlId={ `passenger-${index}-first_name` }
                                        >
                                            <Form.Label>Enter fellow passenger first name</Form.Label>
                                            <Form.Control
                                            type="text"
                                            name={ `passenger-${index}-first_name` }
                                            value={passenger.first_name}
                                            onChange={(e) => setPassengerValue('first_name', e.target.value , index)}
                                            placeholder="e.g John"
                                            ></Form.Control>
                                        </Form.Group>
                                    </div>
                                    <div className='col-lg-6'>
                                        <Form.Group
                                            className="mb-3 col-12 position-relative"
                                            controlId={ `passenger-${index}-last_name` }
                                        >
                                            <Form.Label>Enter fellow passenger last name</Form.Label>
                                            <Form.Control
                                            type="text"
                                            name={ `passenger-${index}-last_name` }
                                            value={passenger.last_name}
                                            onChange={(e) => setPassengerValue('last_name', e.target.value, index)}
                                            placeholder="e.g Vick"
                                            ></Form.Control>
                                        </Form.Group>
                                    </div>
                                </div>

                                {
                                  passenger.under_18 == false && <Form.Group
                                        className="mb-3 col-12 position-relative"
                                        controlId={ `passenger-${index}-email` }
                                    >
                                        <Form.Label>Enter fellow passenger Email</Form.Label>
                                        <Form.Control
                                        type="text"
                                        name={ `passenger-${index}-email` }
                                        value={passenger.email}
                                        onChange={(e) => setPassengerValue('email', e.target.value, index)}
                                        placeholder="john.vick@example.com"
                                        ></Form.Control>
                                    </Form.Group>
                                }

                                <Form.Group
                                    className="mb-3 col-12 d-flex position-relative"
                                    controlId={ `passenger-${index}-same_booking_reference` }
                                >
                                    <Form.Check
                                    type="checkbox"
                                    name="privacy_policy_agreed"
                                    checked={passenger.same_booking_reference}
                                    onChange={(e) => setPassengerValue('same_booking_reference', e.target.checked ? true : false, index)}
                                    >
                                        
                                    </Form.Check>
                                    <Form.Label className='ms-2'>We have the same booking reference/confirmation number <span className='fw-bold'>{ compensationForm.step7?.booking_reference_number }</span> </Form.Label>
                                </Form.Group>


                                {
                                  passenger.same_booking_reference == false&& <Form.Group
                                        className="mb-3 col-12 position-relative"
                                        controlId={ `passenger-${index}-booking_reference` }
                                    >
                                        <Form.Label>Enter fellow passenger booking reference number</Form.Label>
                                        <Form.Control
                                        type="text"
                                        name={ `passenger-${index}-email` }
                                        value={passenger.booking_reference}
                                        onChange={(e) => setPassengerValue('booking_reference', e.target.value, index)}
                                        placeholder="e.g N1TZDF"
                                        ></Form.Control>
                                    </Form.Group>
                                }

                            </div>
                        )
                    })
                }
            </div>
        </div>

        <Formbutton handleContinue={handleContinue} text="Continue" activecontinue={activecontinue}/>
    </div>
  )
}

export default Step9