const Checkbox = ({ selected, children ,image}) => (
  <div className='justify-content-center d-flex align-items-center position-relative' style={{ height:'100%' , width:'100%', position: 'relative', height:'100%', padding: '1rem', textAlign:'center' }}>
    {selected ? <span className="d-flex align-items-center justify-content-center" style={{ backgroundColor:'rgb(255 111 0)' , height:'20px', width:'20px', borderRadius:'50%', position:'absolute', top: ' 7%', right: '0%'}}>
    <svg width="10" height="9" viewBox="0 0 10 9" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0.75 4.50187L3.58 7.33187L9.25 1.67188" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
    </span> : null}
    <span className={`d-flex ${image===true ? 'flex-column':''} align-items-center justify-content-between gap-2`}>{children}</span>
  </div>
);

export default Checkbox;