import { Label } from '@mui/icons-material';
import React, { useEffect, useState } from 'react'
import { Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import ReloadReset from '../ReloadReset';
import Formbutton from '../Formbutton';

function Step8({ compensationForm, setCompensationForm }) {
  const navigate = useNavigate();
  const [activecontinue, setActiveContinue] = useState(false);
  const [form, setForm] = useState({
    first_name: null,
    last_name: null,
    email: null,
    privacy_policy_agreed: false,
  });

  useEffect(() => {

    if (form.first_name && form.last_name && form.email && form.privacy_policy_agreed == true) {
      setActiveContinue(true);
    } else {
      setActiveContinue(false);
    }

  }, [form])


  const handleContinue = () => {
    setCompensationForm({...compensationForm, step8: {
      filerDetails: {
        first_name: form.first_name,
        last_name: form.last_name,
        email: form.email
      },
      privacy_policy_agreed: form.privacy_policy_agreed
    }})

    navigate('/funnel/step9')
  };


  ReloadReset(compensationForm, 'step7');

  return (
    <div className='main-container'>
        <div className='d-flex mx-auto flex-column alight-items-center'>
            <h3>Finish the <b>registration</b> to start the claim.</h3>

            <div className="rounded p-4 mt-3" style={{ backgroundColor: "#f3e3d6" }}>
                <div className='row'>
                    <div className='col-lg-6'>
                        <Form.Group
                            className="mb-3 col-12 position-relative"
                            controlId="first_name"
                        >
                            <Form.Label>First Name</Form.Label>
                            <Form.Control
                            type="text"
                            name="first_name"
                            value={form.first_name}
                            onChange={(e) => setForm({
                                ...form,
                                first_name: e.target.value
                            })}
                            placeholder="e.g John"
                            ></Form.Control>
                        </Form.Group>
                    </div>
                    <div className='col-lg-6'>
                        <Form.Group
                            className="mb-3 col-12 position-relative"
                            controlId="last_name"
                        >
                            <Form.Label>Last Name</Form.Label>
                            <Form.Control
                            type="text"
                            name="last_name"
                            value={form.last_name}
                            onChange={(e) => setForm({
                                ...form,
                                last_name: e.target.value
                            })}
                            placeholder="e.g Vick"
                            ></Form.Control>
                        </Form.Group>
                    </div>
                </div>

                <Form.Group
                    className="mb-3 col-12 position-relative"
                    controlId="email"
                >
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                    type="text"
                    name="email"
                    value={form.email}
                    onChange={(e) => setForm({
                        ...form,
                        email: e.target.value
                    })}
                    placeholder="john.vick@example.com"
                    ></Form.Control>
                </Form.Group>

                <Form.Group
                    className="mb-3 col-12 d-flex position-relative"
                    controlId="privacy_policy_acceptance"
                >
                    <Form.Check
                    type="checkbox"
                    name="privacy_policy_agreed"
                    value={form.privacy_policy_agreed}
                    onChange={(e) => {
                        setForm({
                            ...form,
                            privacy_policy_agreed: e.target.checked ? true : false
                        });
                    }}
                    >
                        
                    </Form.Check>
                    <Form.Label className='ms-2'>I agree to privacy policy</Form.Label>
                </Form.Group>
                
            </div>
        </div>

        <Formbutton handleContinue={handleContinue} text="Continue" activecontinue={activecontinue}/>
    </div>  
  )
}

export default Step8