import React from 'react'
import howit from '../assets/howmedia4.webp'

const HowItWorks = () => {
    let howitwork = [
        {
            title:'Check your airline compensation',
            text: 'Submit your details and we’ll do an instant check to find out if you’re entitled to flight compensation – at no cost to you.'
        },
        {
            title:'File your flight compensation claim',
            text: 'It only takes 5 minutes to file your claim, and our team will do the rest. If needed, we will take airline to court for you.'
        },
        {
            title:'You get paid up to £520 (€600) per passenger',
            text: 'We will notify you once airline paid compensation and we will transfer it to your bank account less our commission.'
        }
        
    ]
  return (
    <div className='how-it-works pb-4 pb-lg-0'>
        <div className='container'>
            <div className='d-flex flex-lg-row flex-column gap-5 align-items-center'> 
                {/* <div className='title d-inline-flex text-center d-md-none w-100'>
                    <h2 className="title-2 fw-medium">How Does it Work?</h2>
                </div> */}
                <div className='how-it-works-left d-md-flex d-none'>
                    <img src={howit} alt="" />
                </div>
                <div className='how-it-works-right'>
                    <h2 className='mb-4 text-center text-lg-start'>How Does it Work?</h2>
                    <div className='howit-elems d-flex flex-column gap-3'>
                        {
                            howitwork.map((elem,i)=>{
                                return(
                                    <div className='howit-elem fw-bold' key={i}>
                                        <h3>{i+1}. {elem.title}</h3>
                                        <p>{elem.text}</p>
                                    </div>

                                )
                            })
                        }
                        

                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default HowItWorks