import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DragNdrop from "../DragNDrop";
import ReloadReset from "../ReloadReset";
import Formbutton from "../Formbutton";

function Step12({ compensationForm, setCompensationForm }) {
  const [activecontinue, setActiveContinue] = useState(false);
  const navigate = useNavigate();

  const [files, setFiles] = useState([]);

  useEffect(() => {
    if (files.length)
    {
        setActiveContinue(true);
    } else {
        setActiveContinue(false);
    }
  }, [files])

  const handleContinue = () => {
    setCompensationForm({
      ...compensationForm,
      step12: {
        files: files,
      },
    });

    navigate("/funnel/step13");
  };

  ReloadReset(compensationForm, "step11");
  return (
    <div className="main-container">
      <div
        className="d-flex mx-auto flex-column alight-items-center"
        style={{ width: "780px" }}
      >
        <h3>
          Gathering the <span className="fw-bold">evidence</span> that proves your case, please include boarding pass and
          e-tickets for your <span className="fw-bold">fellow passengers</span> too.
        </h3>

        <div
          className="rounded p-4 mt-3 d-flex flex-column align-items-end"
          style={{ backgroundColor: "#f3e3d6" }}
        >
          <DragNdrop onFilesSelected={setFiles} width="100%" height="100%" />
        </div>
      </div>
      <Formbutton
        handleContinue={handleContinue}
        text="Continue"
        activecontinue={activecontinue}
      />
    </div>
  );
}

export default Step12;
