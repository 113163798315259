import { faLocationDot, faPiggyBank, faPlaneArrival, faPlaneDeparture, faStar, faUnlock } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, List, ListItem, ListItemText } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Form } from 'react-bootstrap'
import heroimage2 from '../assets/heroimage2.webp'
import FlightCompensationApi from '../../Api/FlightCompensationApi'
import { useNavigate } from 'react-router-dom'

const Hero = ({ compensationForm, setCompensationForm }) => {
    const flightCompensationApi = new FlightCompensationApi();

  const navigate = useNavigate();
  const [formdata, setFormdata] = useState({
    departure: {
      id: null,
      name: '',
    },
    destination: {
      id: null,
      name: ''
    }
  });
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [departureFocus, setDepartureFocus] = useState(false)
  const [destinationFocus, setDestinationFocus] = useState(false)
  const [activeContinue, setActiveContinue] = useState(false);
  const [loading, setLoading] = useState(false);
  useEffect(()=>{
    if(formdata.departure.id !== null && formdata.destination.id !== null){
      setActiveContinue(true)
    }
  },[formdata])
    const handleContinue = () => {
        if(activeContinue){
            setCompensationForm({ ...compensationForm, step1: formdata });
            navigate("/funnel/step2");
        }
        else{
            navigate('/funnel')
        }
      };
      const fetchAirports = async (value) => {
        try{
          setLoading(true)
          const data = await flightCompensationApi.getAirports({
            's': value
          });
          setLoading(false)
          return data;
        }
        catch{
          console.log('Error fetching data:');
          setLoading(false)
        }
      }
  return (
    <div className='hero__section'>
            <div className='main-hero'>
                <div className='container d-flex flex-column flex-lg-row'>
                    <div className='hero-left col-12 col-lg-7'>
                        <div className='based gap-2 d-inline-flex align-items-center justify-content-start'>
                            <FontAwesomeIcon icon={faStar} /> Reviews.io 4.6/5 based on 16,137 reviews
                        </div>
                        <h1 className='mt-2'>Delayed or cancelled flight?</h1>
                        <p className='mt-3 fs-5'>Claim flight compensation up to <strong>£520</strong> (€600) per passenger. Let us fight for your rights, so you get the airline compensation you deserve.</p>
                        <div className='row mt-2 gap-md-0 gap-3'>
                            <Form.Group className="col-md-4 position-relative" controlId="formBasicEmail">
                                <Form.Control type="text" name='departure.name' value={formdata.departure.name} className=' icon-input'  placeholder="Departure" onFocus={() => {
                                    setDestinationFocus(false);
                                    setDepartureFocus(true);
                                }} onChange={async (e) => {

                                    setFormdata({ ...formdata, departure: { id: null, name: e.target.value } })
                                    fetchAirports(e.target.value).then(data => {
                          
                                      setFilteredSuggestions(data.data);
                    
                                    });
                    
                    
                                  }}/>
                                <FontAwesomeIcon className='position-absolute' style={{ right: '28px', bottom: '33%', color: 'grey' }} icon={faPlaneDeparture} />
                                {loading && departureFocus && <div className='rounded position-absolute d-flex align-items-center justify-content-center fs-6' style={{ height: '4rem', border: '2px solid #00000036', backgroundColor: '#fff7ef', top: '5.9rem', zIndex: '20', right: '12px', left: '12px' }}>Loading...</div>}
                                { !loading && departureFocus && filteredSuggestions.length > 0 && 
                                    
                                    <List className='rounded position-absolute' style={{ maxHeight: '12rem', overflowY: 'scroll', border: '2px solid #00000036', backgroundColor: '#fff7ef', top: '3.4rem', zIndex: '20', right: '12px', left: '12px' }}>
                                    {filteredSuggestions.map((suggestion, index) => (
                                        <ListItem className='align-items-start'  key={index} onClick={() => { 
                                            setFormdata({ ...formdata, departure: {id: suggestion.id, name: suggestion.name }});
                                            setDepartureFocus(false);
                                            setFilteredSuggestions([]);
                                        }}>
                                        <FontAwesomeIcon className='me-2' icon={faLocationDot} style={{ color: "#ff6f00",marginTop:'0.7rem' }} />
                                        <ListItemText primary={suggestion.name} secondary={suggestion.city + ", " + suggestion.country} style={{cursor:'pointer'}}/>                    
                                        </ListItem>
                                    ))}
                                    </List>
                                
                                }
                            </Form.Group>
                            <Form.Group className="col-md-4 ps-md-0 position-relative" controlId="formBasicEmail">
                                <Form.Control type="email" name='destination' className=' icon-input'  placeholder="Destination" onFocus={() => {
                                        setDestinationFocus(true);
                                        setDepartureFocus(false);
                                    }} value={formdata.destination.name} onChange={async(e) => {

                                                        
                                        setFormdata({ ...formdata, destination: { id: null, name: e.target.value } })
                                        fetchAirports(e.target.value).then(data => {

                                            setFilteredSuggestions(data.data);

                                        });


                                    }} />
                                <FontAwesomeIcon className='position-absolute' style={{ right: '28px', bottom: '33%', color: 'grey' }} icon={faPlaneArrival} />
                                {loading && destinationFocus && <div className='rounded position-absolute d-flex align-items-center justify-content-center fs-6' style={{ height: '4rem', border: '2px solid #00000036', backgroundColor: '#fff7ef', top: '5.9rem', zIndex: '20', right: '12px', left: '12px' }}>Loading...</div>}
                                {!loading && destinationFocus && filteredSuggestions.length > 0 && (
                                    <List className='rounded position-absolute' style={{ maxHeight: '12rem', overflowY: 'scroll', border: '2px solid #00000036', backgroundColor: '#fff7ef', top: '3.4rem', zIndex: '20', right: '12px', left: '12px' }}>
                                    {filteredSuggestions.map((suggestion, index) => (
                                        <ListItem className='align-items-start' key={index} onClick={() => { 
                                        setFormdata({ ...formdata, destination: {id: suggestion.id, name: suggestion.name }}) 
                                        setFilteredSuggestions([]);
                                        setDestinationFocus(false);
                                        }} style={{alignItems:'flex-start !important'}}>
                                        <FontAwesomeIcon className='me-2' icon={faLocationDot} style={{ color: "#ff6f00",marginTop:'0.7rem' }} />
                                        <ListItemText primary={suggestion.name} secondary={suggestion.city + ", " + suggestion.country} style={{cursor:'pointer'}}/>
                                        </ListItem>
                                    ))}
                                    </List>
                                )}
                            </Form.Group>   
                            <Button className='col-md-4 col-6 fw-bold' style={{backgroundColor:'#fb7117'}} onClick={handleContinue} variant='contained'>Check Compensation</Button>
                            <div className='d-flex flex-column flex-lg-row align-items-center mt-3 gap-3'>
                                <div className='d-flex align-items-center fs-5 gap-3'>
                                    {/* <svg width="26" height="25"><use xlinkHref="../assets/sprite.svg#info1"></use></svg> */}
                                    <FontAwesomeIcon icon={faPiggyBank} />
                                    Wise choice: 5% cheaper service
                                </div>
                                <div className='d-flex align-items-center fs-5 gap-3'>
                                    {/* <svg width="26" height="25"><use xlinkHref="../assets/sprite.svg#info1"></use></svg> */}
                                    <FontAwesomeIcon icon={faUnlock} />
                                    No Win, No Fee
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='hero-right d-lg-flex d-none'>
                        <img src={heroimage2} alt="" />
                    </div>
                </div>
            </div>
        </div>
  )
}

export default Hero