import React, { useEffect, useState } from 'react'
import Sidebar from './Sidebar'
import Topbar from './Topbar'

const FormStatus = ({ compensationForm, setCompensationForm, children }) => {
    const [status1 , setStatus1] = useState('current')
    const [status2 , setStatus2] = useState()
    const [status3 , setStatus3] = useState()
    useEffect(()=>{
        if(compensationForm?.step6){
        console.log("Checking" );
        setStatus1('filled');
        setStatus2('current')
        }
        // if(compensationForm.step5){
        //     setStatus2('filled');
        //     setStatus3('current')
        // }
    },[compensationForm])
  return (
    <>
        <Sidebar status1={status1} status2={status2} status3={status3}/>
        <Topbar status1={status1} status2={status2} status3={status3}/>
        {children}
    </>
  )
}

export default FormStatus