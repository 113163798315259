import React from "react";
import reviewimg from "../assets/reviewlogo.svg";

const Reviews = () => {
  return (
    <div className="reviews__wrapper">
      <div className="container">
        <div className="outer__reviews flex-column flex-lg-row d-flex align-items-start align-items-sm-center align-items-lg-center justify-content-between">
          <span class="d-inline-flex align-items-center justify-content-center">
            <img
              width="230"
              height="32"
              loading="lazy"
              decoding="async"
              src={reviewimg}
              alt="reviews.io"
            />
          </span>
          <p class="m-0">AirAdvisor is rated 4.6/5 </p>
          <div class="button-wrapper">
            <span class="d-inline-flex align-items-center justify-content-center">
              “Excellent” - 16,145 reviews
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Reviews;
