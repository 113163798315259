import React from 'react'
import planedelay from '../assets/Planedelay.svg'
import planedelaywhite from '../assets/Planedelaywhite.svg'
import planecancel from '../assets/Planecancel.svg'
import planecancelwhite from '../assets/Planecancelwhite.svg'
import planerefund from '../assets/Planerefund.svg'
import planerefundwhite from '../assets/Planerefundwhite.svg'
import planestrike from '../assets/Strike.svg'
import planestrikewhite from '../assets/Strikewhite.svg'


const Problem = () => {
    let compensationType = [
        {
            img1: planedelay,
            img2: planedelaywhite,
            title: 'Claim Flight Delay Compensation',
            text:'You get paid up to £520 (€600) per passenger if your flight arrived late by 3 hours or more.'
        },
        {
            img1: planecancel,
            // img2: planedelaywhite,
            img2: planecancelwhite,
            title: 'Claim Cancelled Flight Compensation',
            text:'Flight canceled on a short notice? You may be entitled up to £520 (€600) in compensation on top of your ticket refund. '
        },
        {
            img1: planerefund,
            img2: planerefundwhite,
            // img1: planedelay,
            // img2: planedelaywhite,
            title: 'Flight Ticket Refund',
            text:"If an airline cancells your flight, you can claim a ticket refund. Don't settle for vouchers and demand refund in full. "
        },
        {
            img1: planestrike,
            img2: planestrikewhite,
            // img1: planedelay,
            // img2: planedelaywhite,
            title: 'Airline Strike Compensation',
            text:'Was your flight delayed or cancelled due to an airline strike? In such case, airline must compensate you up to £520 (€600).'
        },
    ]
  return (
    <div className='problems__wrapper'>
        <div className='container'>
            <div className='problem-main'>
                <div className='mx-auto problem-header text-md-center text-left '>
                    <h2 className='fw-bold'>We can help you solve common flight problems </h2>
                    <p className='fw-bold'>Our team of legal experts has worked on over <strong>260,000 cases</strong>, helping passengers receive flight compensation even after they were initially turned down by the airline.</p>
                </div>
                <div className='compensation-types row'>
                    {
                        compensationType.map((type,i)=>{
                            return(
                                <div className='col-xl-3 col-md-6 p-3' key={i}>
                                <div className='compensation-type '>
                                    <img className='planedark' src={type.img1} alt="" />
                                    <img className='planelight' src={type.img2} alt="" />
                                    <div>
                                        <h3>{type.title}</h3>
                                        <p>{type.text}</p>
                                    </div>
                                </div>
                                </div>
                            )
                        })
                    }
                   
                </div>
            </div>
        </div>
    </div>
  )
}

export default Problem