import React from 'react'
import StepReview from './StepReview'

const Topbar = ({status1 , status2, status3}) => {
  return (
    <div className='d-flex gap-3 d-lg-none p-3 align-items-center topbar'>
        <StepReview title='Compensation' status={status1}/>
        <StepReview title='Details' status={status2}/>
        <StepReview title='Claim' status={status3}/>
    </div>
  )
}

export default Topbar