import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlane } from "@fortawesome/free-solid-svg-icons";
import reason1 from "../assets/reason1.svg";
import reason2 from "../assets/reason2.svg";
import reason3 from "../assets/reason3.svg";
import reason4 from "../assets/reason4.svg";
import reason5 from "../assets/reason5.svg";
import reason6 from "../assets/reason6.svg";
import reason7 from "../assets/reason7.svg";
import reason8 from "../assets/reason8.svg";
import Checkbox from "./Checkbox";
import Option from "./Option";
import ReloadReset from "../ReloadReset";
import Formbutton from "../Formbutton";
import FlightCompensationApi from "../../Api/FlightCompensationApi";

const Step5 = ({ compensationForm, setCompensationForm }) => {
  const flightCompensationApi = new FlightCompensationApi();
  const flight = compensationForm?.step4
    ? compensationForm?.step4.compensationReason
    : null;
  const [delayTimes, setDelayTimes] = useState([
    {
      label: "Less than 3 hours",
      value: "<3h",
    },
    {
      label: "3 hours",
      value: "3h",
    },
    {
      label: "More than 3 hours",
      value: ">3h",
    },
    {
      label: "Never arrived",
      value: "0",
    },
  ]);

  const cancelTimes = [
    {
      label: "Less than 7 days",
      value: "<7d",
    },
    {
      label: "From 7 to 14 days",
      value: ">7d<14d",
    },
    {
      label: "More than 14 days",
      value: ">14d",
    },
  ];

  const [totalDelays, setTotalDelays] = useState([
    {
      label: "Less than 2 hours",
      value: "<2h",
    },
    {
      label: "2 to 3 hours",
      value: ">2h<3h",
    },
    {
      label: "More than 3 hours",
      value: ">3h",
    },
    {
      label: "Never arrived",
      value: "0",
    },
  ]);

  const [activecontinue, setActiveContinue] = useState(false);
  const [distance, setDistance] = useState();

  const [delayTime, setDelayTime] = useState({});
  const [reason, setReason] = useState(null);

  const [cancelTime, setCancelTime] = useState({
    label: "",
    value: "",
  });
  const [alternateFlightOffered, setAlternateFlightOffered] = useState(null);
  const [totalDelay, setTotalDelay] = useState({
    label: "",
    value: "",
  });
  const [refunded, setRefunded] = useState(null);
  const [ticketPrice, setTicketPrice] = useState("");

  const [denied, setDenied] = useState(null);
  const [giveUpSeat, setGiveUpSeat] = useState(null);

  const navigate = useNavigate();
  const handleContinue = () => {
    if (flight === "delay") {
      setCompensationForm({
        ...compensationForm,
        step5: { reason, delayTime },
      });
    } else if (flight === "cancelled") {
      setCompensationForm({
        ...compensationForm,
        step5: {
          alternateFlightOffered,
          cancelTime,
          totalDelay,
          refunded,
          ticketPrice,
        },
      });
    } else {
        setCompensationForm({
            ...compensationForm,
            step5: {
              delayTime,
              giveUpSeat,
              alternateFlightOffered,
              refunded,
              ticketPrice,
            },
        });
    //   giveUpSeat === "Yes"
    //     ? setCompensationForm({ ...compensationForm, step5: { giveUpSeat, delayTime, giveUpSeat } })
    //     : alternateFlightOffered === "Yes"
    //     ? setCompensationForm({
    //         ...compensationForm,
    //         step5: { giveUpSeat, alternateFlightOffered, delayTime },
    //       })
    //     : refunded === "Yes"
    //     ? setCompensationForm({
    //         ...compensationForm,
    //         step5: { giveUpSeat, alternateFlightOffered, refunded, delayTime },
    //       })
    //     : setCompensationForm({
    //         ...compensationForm,
    //         step5: {
    //           delayTime,
    //           giveUpSeat,
    //           alternateFlightOffered,
    //           refunded,
    //           ticketPrice,
    //         },
        //   });
    }
    navigate("/funnel/step6");
    console.log(reason);
  };

  useEffect(() => {
    flightCompensationApi
      .calculateFlightDistance(
        compensationForm?.step3?.affectedFlight?.from.id,
        compensationForm?.step3?.affectedFlight?.to.id
      )
      .then((res) => {
        setDistance(res.distance);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [compensationForm.step3]);

  useEffect(() => {
    if (distance > 3500) {

      setTotalDelays([
        
        {
          label: "Less than 4 hours",
          value: ">3h",
        },
        {
          label: "More than 4 hours",
          value: ">4h",
        },
       
      ]);

    } else {

      setTotalDelays([
        {
          label: "Less than 3 hours",
          value: "<3h",
        },
        {
          label: "3 hours",
          value: "3h",
        },
        {
          label: "More than 3 hours",
          value: ">3h",
        },
        {
          label: "Never arrived",
          value: "0",
        },
      ]);

    }
  }, [distance, alternateFlightOffered]);

  ReloadReset(compensationForm, "step4");
  return (
    <>
      {flight === "delay" && (
        <>
          <div className="main-container p-5" style={{ minHeight: "100vh" }}>
            <div className="d-flex mx-auto flex-column align-items-center">
              <h3 className="text-center my-2">
                What was the total delay once you arrived at{" "}
                <span className="fw-bold">
                  {compensationForm?.step1?.destination.name}?
                </span>
              </h3>
              <div className="form2 mb-5">
                {delayTimes.map((item, index) => {
                  return (
                    <>
                      <Option
                        className="formoption"
                        key={"delay" + index}
                        selected={delayTime?.value === item.value}
                        onClick={() => {
                          setActiveContinue(false);
                          setDelayTime({
                            label: item.label,
                            value: item.value,
                          });
                        }}
                      >
                        <Checkbox selected={delayTime?.value === item.value}>
                          <p>{item.label}</p>
                        </Checkbox>
                      </Option>
                    </>
                  );
                })}
              </div>
              {delayTime.value && (
                <div>
                  <h3 className="text-center my-2">
                    What was flight delay reason?
                  </h3>
                  <div className="row mt-5">
                    <Option
                      className="formoption delay col-12 col-md-3"
                      selected={reason === "Not provided"}
                      onClick={() => {
                        setActiveContinue(true);
                        setReason("Not provided");
                      }}
                    >
                      <Checkbox
                        selected={reason === "Not provided"}
                        image={true}
                      >
                        <div className="flight">
                          <img src={reason1} className="flight-img"></img>
                        </div>
                        <p>Not provided</p>
                      </Checkbox>
                    </Option>
                    <Option
                      className="formoption delay col-12 col-md-3"
                      selected={reason === "Technical Issues"}
                      onClick={() => {
                        setActiveContinue(true);
                        setReason("Technical Issues");
                      }}
                    >
                      <Checkbox
                        selected={reason === "Technical Issues"}
                        image={true}
                      >
                        <div className="flight">
                          <img src={reason2} className="flight-img"></img>
                        </div>
                        <p>Technical Issues</p>
                      </Checkbox>
                    </Option>
                    <Option
                      className="formoption delay col-12 col-md-3"
                      selected={reason === "Issues at airport"}
                      onClick={() => {
                        setActiveContinue(true);
                        setReason("Issues at airport");
                      }}
                    >
                      <Checkbox
                        selected={reason === "Issues at airport"}
                        image={true}
                      >
                        <div className="flight">
                          <img src={reason4} className="flight-img"></img>
                        </div>
                        <p>Issues at airport</p>
                      </Checkbox>
                    </Option>
                  </div>

                  <div className="row mt-3">
                    <Option
                      className="formoption delay col-12 col-md-3"
                      selected={reason === "Weather Condition"}
                      onClick={() => {
                        setActiveContinue(true);
                        setReason("Weather Condition");
                      }}
                    >
                      <Checkbox
                        selected={reason === "Weather Condition"}
                        image={true}
                      >
                        <div className="flight">
                          <img src={reason5} className="flight-img"></img>
                        </div>
                        <p>Weather Condition</p>
                      </Checkbox>
                    </Option>
                    <Option
                      className="formoption delay col-12 col-md-3"
                      selected={reason === "Influence of other flights"}
                      onClick={() => {
                        setActiveContinue(true);
                        setReason("Influence of other flights");
                      }}
                    >
                      <Checkbox
                        selected={reason === "Influence of other flights"}
                        image={true}
                      >
                        <div className="flight">
                          <img src={reason6} className="flight-img"></img>
                        </div>
                        <p>Influence of other flights</p>
                      </Checkbox>
                    </Option>
                    <Option
                      className="formoption delay col-12 col-md-3"
                      selected={reason === "delay"}
                      onClick={() => {
                        setActiveContinue(true);
                        setReason("delay");
                      }}
                    >
                      <Checkbox selected={reason === "delay"} image={true}>
                        <div className="flight">
                          <img src={reason7} className="flight-img"></img>
                        </div>
                        <p>Strike</p>
                      </Checkbox>
                    </Option>
                  </div>
                </div>
              )}
            </div>
          </div>
        </>
      )}
      {flight === "cancelled" && (
        <div className="main-container p-5" style={{ minHeight: "100vh" }}>
          <div className="d-flex mx-auto flex-column align-items-center">
            <h3 className="text-center my-2">
              How far in advance did the airline notify you of the cancellation?
            </h3>
            <div className="form2 mb-5">
              {cancelTimes.map((item, index) => {
                return (
                  <div key={"cancelTime" + index}>
                    <Option
                      className="formoption"
                      key={"delay" + index}
                      selected={cancelTime?.value === item.value}
                      onClick={() => {
                        setCancelTime({
                          label: item.label,
                          value: item.value,
                        });
                      }}
                    >
                      <Checkbox selected={cancelTime?.value === item.value}>
                        <p>{item.label}</p>
                      </Checkbox>
                    </Option>
                  </div>
                );
              })}
            </div>
            {cancelTime.value && (
              <div>
                <h3>
                  Did you use an alternative flight offered by the airline?
                </h3>
                <div className="row mt-4">
                  <Option
                    className="formoption  col-12 col-md-5 h-100"
                    selected={alternateFlightOffered === "Yes"}
                    onClick={() => {
                      setActiveContinue(false);
                      setAlternateFlightOffered("Yes");
                      setRefunded(null);
                    }}
                  >
                    <Checkbox selected={alternateFlightOffered === "Yes"}>
                      <p>
                        <b>Yes</b>
                      </p>
                    </Checkbox>
                  </Option>
                  <Option
                    className="formoption  col-12 col-md-5 h-100"
                    selected={alternateFlightOffered === "No"}
                    onClick={() => {
                      setAlternateFlightOffered("No");
                      setTotalDelay({
                        label: "",
                        value: "",
                      });
                    }}
                  >
                    <Checkbox selected={alternateFlightOffered === "No"}>
                      <p>
                        <b>No</b>
                      </p>
                    </Checkbox>
                  </Option>
                </div>
              </div>
            )}
            {alternateFlightOffered === "Yes" && (
              <div className="my-4">
                <h3>
                  What was the total delay once you arrived at{" "}
                  <span className="fw-bold">
                    {compensationForm?.step1?.destination?.name}?
                  </span>{" "}
                </h3>
                <div className="row mt-4">
                  {totalDelays.map((item, index) => {
                    return (
                      <div className="col-lg-3" key={"delay" + index}>
                        <Option
                          className="formoption delay w-100 my-3"
                          selected={totalDelay.value === item.value}
                          onClick={() => {
                            setActiveContinue(false);
                            setTotalDelay({
                              label: item.label,
                              value: item.value,
                            });
                          }}
                        >
                          <Checkbox selected={totalDelay.value === item.value}>
                            <p>{item.label}</p>
                          </Checkbox>
                        </Option>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}

            {alternateFlightOffered === "No" && (
              <div className="my-4">
                <h3>Was your ticket cost refunded?</h3>
                <div className="row mt-3">
                  <Option
                    className="formoption h-100 col-12 col-md-5"
                    selected={refunded === "Yes"}
                    onClick={() => {
                      setActiveContinue(true);
                      setRefunded("Yes");
                    }}
                  >
                    <Checkbox selected={refunded === "Yes"}>
                      <p>
                        <b>Yes</b>
                      </p>
                    </Checkbox>
                  </Option>
                  <Option
                    className="formoption h-100 col-12 col-md-5"
                    selected={refunded === "No"}
                    onClick={() => {
                      setActiveContinue(false);
                      setRefunded("No");
                    }}
                  >
                    <Checkbox selected={refunded === "No"}>
                      <p>
                        <b>No</b>
                      </p>
                    </Checkbox>
                  </Option>
                </div>
              </div>
            )}
            {refunded === "No" && alternateFlightOffered === "No" && (
              <div
                className="p-3 rounded my-4 w-50"
                style={{ backgroundColor: "#f3e3d6" }}
              >
                <Form.Group
                  className="mb-3 position-relative"
                  controlId="formBasicEmail"
                >
                  <Form.Label>
                    Specify total ticket price to be refunded
                  </Form.Label>
                  <Form.Control
                    autoComplete="off"
                    type="number"
                    name="ticketPrice"
                    required
                    className="relative"
                    value={ticketPrice}
                    onChange={(e) => {
                      if (e.target.value) {
                        setActiveContinue(true);
                      } else {
                        setActiveContinue(false);
                      }

                      setTicketPrice(e.target.value);
                    }}
                    placeholder="EUR"
                  ></Form.Control>
                </Form.Group>
              </div>
            )}
            {totalDelay.value && (
              <div className="my-4">
                <h3>
                  Why was your <b>flight canceled</b>?
                </h3>
                <div className="row">
                  <Option
                    className="formoption delay col-12 col-md-3"
                    selected={reason === "Not provided"}
                    onClick={() => {
                      setActiveContinue(true);
                      setReason("Not provided");
                    }}
                  >
                    <Checkbox selected={reason === "Not provided"} image={true}>
                      <div className="flight">
                        <img src={reason1} className="flight-img"></img>
                      </div>
                      <p>Not provided</p>
                    </Checkbox>
                  </Option>
                  <Option
                    className="formoption delay col-12 col-md-3"
                    selected={reason === "Technical Issues"}
                    onClick={() => {
                      setActiveContinue(true);
                      setReason("Technical Issues");
                    }}
                  >
                    <Checkbox
                      selected={reason === "Technical Issues"}
                      image={true}
                    >
                      <div className="flight">
                        <img src={reason2} className="flight-img"></img>
                      </div>
                      <p>Technical Issues</p>
                    </Checkbox>
                  </Option>
                  <Option
                    className="formoption delay col-12 col-md-3"
                    selected={reason === "Unkown"}
                    onClick={() => {
                      setActiveContinue(true);
                      setReason("Unkown");
                    }}
                  >
                    <Checkbox selected={reason === "Unkown"} image={true}>
                      <div className="flight">
                        <img src={reason3} className="flight-img"></img>
                      </div>
                      <p>Unkown</p>
                    </Checkbox>
                  </Option>
                  <Option
                    className="formoption delay col-12 col-md-3"
                    selected={reason === "Issues at airport"}
                    onClick={() => {
                      setActiveContinue(true);
                      setReason("Issues at airport");
                    }}
                  >
                    <Checkbox
                      selected={reason === "Issues at airport"}
                      image={true}
                    >
                      <div className="flight">
                        <img src={reason4} className="flight-img"></img>
                      </div>
                      <p>Issues at airport</p>
                    </Checkbox>
                  </Option>
                </div>

                <div className="row mt-3">
                  <Option
                    className="formoption delay col-12 col-md-3"
                    selected={reason === "Weather Condition"}
                    onClick={() => {
                      setActiveContinue(true);
                      setReason("Weather Condition");
                    }}
                  >
                    <Checkbox
                      selected={reason === "Weather Condition"}
                      image={true}
                    >
                      <div className="flight">
                        <img src={reason5} className="flight-img"></img>
                      </div>
                      <p>Weather Condition</p>
                    </Checkbox>
                  </Option>
                  <Option
                    className="formoption delay col-12 col-md-3"
                    selected={reason === "Influence of other flights"}
                    onClick={() => {
                      setActiveContinue(true);
                      setReason("Influence of other flights");
                    }}
                  >
                    <Checkbox
                      selected={reason === "Influence of other flights"}
                      image={true}
                    >
                      <div className="flight">
                        <img src={reason6} className="flight-img"></img>
                      </div>
                      <p>Influence of other flights</p>
                    </Checkbox>
                  </Option>
                  <Option
                    className="formoption delay col-12 col-md-3"
                    selected={reason === "delay"}
                    onClick={() => {
                      setActiveContinue(true);
                      setReason("delay");
                    }}
                  >
                    <Checkbox selected={reason === "delay"} image={true}>
                      <div className="flight">
                        <img src={reason7} className="flight-img"></img>
                      </div>
                      <p>Strike</p>
                    </Checkbox>
                  </Option>
                  <Option
                    className="formoption delay col-12 col-md-3"
                    selected={reason === "Corona-Virus"}
                    onClick={() => {
                      setActiveContinue(true);
                      setReason("Corona-Virus");
                    }}
                  >
                    <Checkbox selected={reason === "Corona-Virus"} image={true}>
                      <div className="flight">
                        <img src={reason8} className="flight-img"></img>
                      </div>
                      <p>Corona-Virus</p>
                    </Checkbox>
                  </Option>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      {flight === "denied" && (
        <div className="main-container p-5" style={{ height: "100vh" }}>
          <div className="d-flex mx-auto flex-column align-items-center">
            <h3 className="text-center my-2">
              Did you voluntarily give up your seat?
            </h3>
            <div className="form2 mb-5">
              <Option
                className="formoption"
                selected={giveUpSeat === "Yes"}
                onClick={() => {
                  setActiveContinue(true);
                  setGiveUpSeat("Yes");
                }}
              >
                <Checkbox selected={giveUpSeat === "Yes"}>
                  <p>Yes</p>
                </Checkbox>
              </Option>
              <Option
                className="formoption"
                selected={giveUpSeat === "No"}
                onClick={() => {
                  setGiveUpSeat("No");
                }}
              >
                <Checkbox selected={giveUpSeat === "No"}>
                  <p>No</p>
                </Checkbox>
              </Option>
            </div>
            {giveUpSeat === "No" && (
              <div>
                <h3 className="text-center my-2">
                  Did you use an alternative flight offered by the airline?
                </h3>
                <div className="form2 mb-5">
                  <Option
                    className="formoption"
                    selected={alternateFlightOffered === "Yes"}
                    onClick={() => {
                      setAlternateFlightOffered("Yes");
                    }}
                  >
                    <Checkbox selected={alternateFlightOffered === "Yes"}>
                      <p>Yes</p>
                    </Checkbox>
                  </Option>
                  <Option
                    className="formoption"
                    selected={alternateFlightOffered === "No"}
                    onClick={() => {
                      setAlternateFlightOffered("No");
                    }}
                  >
                    <Checkbox selected={alternateFlightOffered === "No"}>
                      <p>No</p>
                    </Checkbox>
                  </Option>
                </div>
              </div>
            )}
            {alternateFlightOffered === "Yes" && (
              <div>
                <h3 className="text-center my-2">
                  What was the total delay once you arrived at{" "}
                  <span className="fw-bold">
                    {compensationForm?.step1?.destination?.name}?
                  </span>
                </h3>

                <div className="form2 mb-5">
                  <Option
                    className="formoption"
                    selected={delayTime.value === '<3h'}
                    onClick={() => {
                      setActiveContinue(true);
                      setDelayTime({
                        label: 'Less then 3 hours',
                        value: "<3h"
                      });
                    }}
                  >
                    <Checkbox selected={delayTime.value === '<3h'}>
                      <p>
                        Less then <strong>3</strong> hours
                      </p>
                    </Checkbox>
                  </Option>
                  <Option
                    className="formoption"
                    selected={delayTime.value === '>3h'}
                    onClick={() => {
                      setActiveContinue(true);
                      setDelayTime({
                        label: "3 hours or more",
                        value: ">3h"
                      });
                    }}
                  >
                    <Checkbox selected={delayTime.value === '>3h'}>
                      <p>
                        <strong>3</strong> hours or more
                      </p>
                    </Checkbox>
                  </Option>
                  <Option
                    className="formoption"
                    selected={delayTime.value === '0'}
                    onClick={() => {
                      setActiveContinue(true);
                      setDelayTime({
                        label: 'Never Arrived',
                        value: '0'
                      });
                    }}
                  >
                    <Checkbox selected={delayTime.value === '0'}>
                      <p>Never Arrived</p>
                    </Checkbox>
                  </Option>
                </div>
              </div>
            )}
            {alternateFlightOffered === "No" && (
              <div className="my-4">
                <h3>Was your ticket cost refunded?</h3>
                <div className="row mt-3">
                  <Option
                    className="formoption h-100 col-12 col-md-5"
                    selected={refunded === "Yes"}
                    onClick={() => {
                      setActiveContinue(true);
                      setRefunded("Yes");
                    }}
                  >
                    <Checkbox selected={refunded === "Yes"}>
                      <p>
                        <b>Yes</b>
                      </p>
                    </Checkbox>
                  </Option>
                  <Option
                    className="formoption h-100 col-12 col-md-5"
                    selected={refunded === "No"}
                    onClick={() => {
                      setRefunded("No");
                    }}
                  >
                    <Checkbox selected={refunded === "No"}>
                      <p>
                        <b>No</b>
                      </p>
                    </Checkbox>
                  </Option>
                </div>
              </div>
            )}
            {refunded === "No" && alternateFlightOffered === "No" && (
              <div
                className="p-3 rounded my-4 w-50"
                style={{ backgroundColor: "#f3e3d6" }}
              >
                <Form.Group
                  className="mb-3 position-relative"
                  controlId="formBasicEmail"
                >
                  <Form.Label>
                    Specify total ticket price to be refunded
                  </Form.Label>
                  <Form.Control
                    autoComplete="off"
                    type="number"
                    name="ticketPrice"
                    required
                    className="relative"
                    value={ticketPrice}
                    onChange={(e) => {
                      setActiveContinue(true);
                      setTicketPrice(e.target.value);
                    }}
                    placeholder="EUR"
                  ></Form.Control>
                </Form.Group>
              </div>
            )}
          </div>
        </div>
      )}
      <Formbutton
        handleContinue={handleContinue}
        activecontinue={activecontinue}
      />
    </>
  );
};

export default Step5;
